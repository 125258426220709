import React, { useEffect, useMemo, useState } from 'react';
import { Control, UseFormGetValues, UseFormSetValue } from 'react-hook-form';
import { observer } from 'mobx-react-lite';

import { Grid, GridControlButton, TPagination } from 'components/grid';
import { IconButton } from 'components/button';
import useDebounce from 'components/hook/useDebounce';
import { PureInput } from 'components/form/textField';
import DialogConfirm from 'components/modal/dialogConfirm';
import Notification from 'components/modal/Notification';

import {
  JurisdictionFormType,
  JurisdictionZipCodesType,
  storeJurisdiction
} from 'stores/_mobx/systemSetup/masterSetting/jurisdiction';
import { ZipType } from 'stores/_mobx/systemSetup/masterSetting/zipCode';

import { getPagination } from 'utils/getPagination';
import DialogZipCodeList from './DialogZipCodeList';

interface PropsType {
  isNew?: boolean;
  id?: number;
  reset?: boolean;
  control?: Control<JurisdictionFormType>;
  setValue?: UseFormSetValue<JurisdictionFormType>;
  getValues?: UseFormGetValues<JurisdictionFormType>
}

const zipCodeExistsNotification = 'This ZIP code already exists!';

const JurisdictionZipCodesGrid = ({
  isNew,
  id,
  setValue,
  getValues,
  reset,
}: PropsType ) => {
  const {
    jurisdictionZipCodeList,
    jurisdictionZipCodesTotal,
    jurisdictionZipCodeRangesTotal,
    fetchingZipCodes,
  } = storeJurisdiction;

  const COLUMNS = useMemo(() => [
    { name: 'zip', title: 'Zip' },
    {
      name: 'action',
      title: 'Action',
      className: 'width-75',
      render: (value: any, { zip }: any) => (
        <IconButton
          className="text-primary"
          onClick={(event: React.SyntheticEvent) => {
            event.preventDefault();
            setZipCodeToDelete(zip);
            toggleDeleteZipCodeDialog(true);
          }}>
          <i className="bi bi-trash" />
        </IconButton>
      ),
    },
  ], []);

  const [zipCodeFilter, setZipCodeFilter] = useState<string>('');
  const [pagination, setPagination] = useState<TPagination>(getPagination());
  const [isAddZipCodeDialogVisible, toggleAddZipCodeDialog] = useState<boolean>(false);
  const [isDeleteZipCodeDialogVisible, toggleDeleteZipCodeDialog] = useState<boolean>(false);
  const [zipCodeToDelete, setZipCodeToDelete] = useState<string>('');

  const [zipCodeList, setZipCodeList] = useState<JurisdictionZipCodesType[]>([]);

  const debouncedMethod = useDebounce(setPagination);

  const handleSetZip = (zip: number) => {
    setZipCodeFilter(zip.toString());

    debouncedMethod(getPagination());
  };

  const onAddZipCode = (zipCode: ZipType) => {
    if (isNew) {
      const isZipCodeExists = zipCodeList.some(
        ({ zip }: JurisdictionZipCodesType) => zip === zipCode.zip
      );

      if (isZipCodeExists) {
        Notification.danger(zipCodeExistsNotification);
      } else {
        setZipCodeList([
          ...zipCodeList,
          { refid: '', jurisdiction: '', zip: zipCode.zip }
        ]);

        if (setValue && getValues) {
          const zipCodeValues = getValues('zipCodes');

          setValue(
            `zipCodes.${zipCodeValues.length}`,
            { refid: '', jurisdiction: '', zip: zipCode.zip },
            { shouldDirty: true, shouldTouch: true }
          );
        }

        toggleAddZipCodeDialog(false);
      }
    } else {
      storeJurisdiction.getJurisdictionZipCodeExists(id, zipCode.zip)
        .then((isCodeExists) => {
          if (isCodeExists) {
            Notification.danger(zipCodeExistsNotification);
          } else {
            storeJurisdiction.addJurisdictionZipCode(id, zipCode.zip)
              .then(isSucceed => {
                if (isSucceed) {
                  const filter = zipCodeFilter === '' ? { zip: '' } : { zip: zipCodeFilter };
                  const paginationZipCodes = {
                    skip: pagination.skip,
                    limit: pagination.pageSize
                  };

                  if (setValue && getValues) {
                    const zipCodeValues = getValues('zipCodes');

                    setValue(
                      `zipCodes.${zipCodeValues.length}`,
                      { refid: '', jurisdiction: `${id}` || '', zip: zipCode.zip },
                      { shouldDirty: true, shouldTouch: true }
                    );
                  }

                  storeJurisdiction.getJurisdictionZipCodes(id, filter, paginationZipCodes);
                } else {
                  Notification.danger('An error occurred!');
                }
                toggleAddZipCodeDialog(false);
              });
          }
        });
    }
  };

  const onDeleteZipCode = () => {
    if (isNew) {
      if (getValues) {
        const zipCodeValues = getValues('zipCodes');

        const filteredZipCodeList = zipCodeValues.filter(
          ({ zip }: JurisdictionZipCodesType) => zip !== zipCodeToDelete
        );

        if (setValue) {
          setValue(
            'zipCodes',
            zipCodeValues.filter(el => el.zip !== zipCodeToDelete),
            { shouldDirty: true, shouldTouch: true }
          );
        }

        setZipCodeList(filteredZipCodeList);
      }

      toggleDeleteZipCodeDialog(false);
    } else {
      if (jurisdictionZipCodesTotal > 1 || jurisdictionZipCodeRangesTotal > 0) {
        storeJurisdiction.deleteJurisdictionZipCode(id, zipCodeToDelete)
          .then(isSucceed => {
            if (isSucceed) {
              const filter = zipCodeFilter === '' ? { zip: '' } : { zip: zipCodeFilter };
              const paginationZipCodes = {
                skip: pagination.skip,
                limit: pagination.pageSize
              };

              if (setValue && getValues) {
                const zipCodeValues = getValues('zipCodes');

                setValue(
                  'zipCodes',
                  zipCodeValues.filter(el => el.zip !== zipCodeToDelete),
                  { shouldDirty: true, shouldTouch: true }
                );
              }

              storeJurisdiction.getJurisdictionZipCodes(id, filter, paginationZipCodes);
            } else {
              Notification.danger('An error occurred!');
            }

            toggleDeleteZipCodeDialog(false);
          });
      } else {
        Notification.warning(
          "You can't delete ZIP code! At least one ZIP code or ZIP code range is required."
        );
      }

      toggleDeleteZipCodeDialog(false);
    }
  };

  useEffect(() => {
    if (!isNew) {
      const filter = zipCodeFilter === '' ? { zip: '' } : { zip: zipCodeFilter };
      const paginationZipCodes = {
        skip: pagination.skip,
        limit: pagination.pageSize
      };

      storeJurisdiction.getJurisdictionZipCodes(id, filter, paginationZipCodes);
    }

    return storeJurisdiction.clearJurisdictionsZipCodes;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNew, id, pagination]);

  useEffect(() => {
    setZipCodeList(jurisdictionZipCodeList);
  }, [jurisdictionZipCodeList, reset]);

  return (
      <div className="row">
        {!isNew ? (
          <PureInput
            label="Zip"
            type="number"
            className="col-md-4 col-sm-4"
            value={zipCodeFilter}
            onChange={handleSetZip}
          />
        ) : null}

        <Grid
          id="jurisdictionZipCodeList"
          onAjax={fetchingZipCodes}
          columns={COLUMNS}
          disablePagination={isNew}
          dataSource={zipCodeList}
          dataSourceCount={jurisdictionZipCodesTotal}
          pagination={pagination}
          onPaginationChange={setPagination}
          gridControlPanel={
            <GridControlButton
              title="Add"
              onClick={() =>
                toggleAddZipCodeDialog(true)
              }
            />
          }
        />

        {isDeleteZipCodeDialogVisible && (
          <DialogConfirm
            onCancel={() => toggleDeleteZipCodeDialog(false)}
            onApprove={onDeleteZipCode}
          >
            Are you sure you want to delete?
          </DialogConfirm>
        )}

        {isAddZipCodeDialogVisible && (
          <DialogZipCodeList
            onChoose={onAddZipCode}
            onCancel={() => toggleAddZipCodeDialog(false)}
          />
        )}
      </div>
  );
};

export default observer(JurisdictionZipCodesGrid);