import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { zipValidator } from 'utils/formValidators';

const validation = yupResolver(
  yup.object().shape({
    stateId: yup
      .number()
      .typeError('Please, select a State!')
      .positive('Please, select a State!'),
    area: yup
      .string()
      .nullable()
      .required('Please, enter an Area!'),
    modalityList: yup.array().of(
      yup.object().shape({
        npinumber: yup
          .string()
          .test({
            test: (npi: string) => npi.length > 0 ? npi.length === 10 : true,
            message: 'NPI have to contain 10 digits length',
          }),
      })
    ),
    zipCodes: yup.array().of(
      yup.object({
        zip: yup.string().required('ZIP code is required')
      })
    ).test(
      'zipCodes-or-zipCodeRanges',
      'At least one ZIP code or ZIP code range is required',
      function(value, context) {
        const { zipCodeRanges } = context.parent;
        return value.length > 0 || zipCodeRanges.length > 0;
      }
    ),
    zipCodeRanges: yup.array().of(
      yup.object({
        zipStart: yup.string().required('Start of ZIP range is required'),
        zipEnd: yup.string().required('End of ZIP range is required'),
      }).test(
        'valid-zip-range',
        'Invalid ZIP code range',
        (obj) => zipValidator(obj.zipStart) && zipValidator(obj.zipEnd)
      )
    ),
  })
);

export default validation;
