import React, { useEffect, useMemo, useState } from 'react';
import { Control, UseFormGetValues, UseFormSetValue } from 'react-hook-form';
import { observer } from 'mobx-react-lite';

import { PureInput } from 'components/form/textField';
import { Grid, GridControlButton, TPagination } from 'components/grid';
import { IconButton } from 'components/button';
import DialogConfirm from 'components/modal/dialogConfirm';
import Notification from 'components/modal/Notification';
import useDebounce from 'components/hook/useDebounce';

import JurisdictionZipCodeRangeDetailsModal
  from 'page/system-setup/master-setup/jurisdiction/JurisdictionZipCodeRangeDetailsModal';

import {
  JurisdictionFormType,
  JurisdictionZipCodeRangesType,
  storeJurisdiction,
  ZipCodeRangesType,
} from 'stores/_mobx/systemSetup/masterSetting/jurisdiction';

import { getPagination } from 'utils/getPagination';

interface PropsType {
  isNew?: boolean;
  id: number;
  reset?: boolean;
  control?: Control<JurisdictionFormType>;
  setValue?: UseFormSetValue<JurisdictionFormType>;
  getValues?: UseFormGetValues<JurisdictionFormType>;
}

const zipCodeExistsNotification = 'This ZIP code range already exists!';

const JurisdictionZipCodeRangesGrid = ({
  isNew,
  id,
  setValue,
  getValues,
  reset,
}: PropsType ) => {
  const {
    jurisdictionZipCodeRangesList,
    jurisdictionZipCodeRangesTotal,
    jurisdictionZipCodesTotal,
    fetchingZipCodeRanges,
  } = storeJurisdiction;

  const COLUMNS = useMemo(() => [
    { name: 'zipStart', title: 'ZIP start' },
    { name: 'zipEnd', title: 'ZIP end' },
    {
      name: 'action',
      title: 'Action',
      className: 'width-75',
      render: (value: any, data: any) => (
        <IconButton
          className="text-primary"
          onClick={(event: React.SyntheticEvent) => {
            event.preventDefault();

            const rangeToDelete = {
              zipStart: data?.zipStart || '',
              zipEnd: data?.zipEnd || ''
            };
            setZipCodeRangeToDelete(rangeToDelete);
            toggleDeleteZipCodeRangesDialog(true);
          }}
        >
          <i className="bi bi-trash" />
        </IconButton>
      ),
    },
  ], []);

  const [zipCodeStartFilter, setZipCodeStartFilter] = useState<string>('');
  const [zipCodeEndFilter, setZipCodeEndFilter] = useState<string>('');
  const [pagination, setPagination] = useState<TPagination>(getPagination());
  const [isAddZipCodeRangesDialogVisible, toggleAddZipCodeRangesDialog] = useState<boolean>(false);
  const [isDeleteZipCodeRangesDialogVisible, toggleDeleteZipCodeRangesDialog] = useState<boolean>(false);
  const [zipCodeRangeToDelete, setZipCodeRangeToDelete] = useState<ZipCodeRangesType>(null);

  const [zipCodeRangesList, setZipCodeRangesList] = useState<JurisdictionZipCodeRangesType[]>([]);

  const debouncedMethod = useDebounce(setPagination);

  const handleSetZipStart = (zip: number) => {
    setZipCodeStartFilter(zip.toString());

    debouncedMethod(getPagination());
  };

  const handleSetZipEnd = (zip: number) => {
    setZipCodeEndFilter(zip.toString());

    debouncedMethod(getPagination());
  };

  const onZipCodeRangeDetailsCallback = (confirm: boolean, range: any) => {
    if (confirm) {
      if (isNew) {
        const isRangeExist = zipCodeRangesList.some(
          ({ zipStart, zipEnd }: JurisdictionZipCodeRangesType) =>
            zipStart === range.zipStart && zipEnd === range.zipEnd
        );

        if (isRangeExist) {
          Notification.danger(zipCodeExistsNotification);
        } else {
          setZipCodeRangesList([...zipCodeRangesList, range]);

          if (setValue && getValues) {
            const zipCodeRangesValues = getValues('zipCodeRanges');

            setValue(
              `zipCodeRanges.${zipCodeRangesValues.length}`,
              { refid: '', jurisdiction: '', ...range },
              { shouldDirty: true, shouldTouch: true }
            );
          }

          toggleAddZipCodeRangesDialog(false);
        }
      } else {
        storeJurisdiction.getJurisdictionZipCodeRangesExists(id, range.zipStart, range.zipEnd)
          .then((exists) => {
            if (exists) {
              Notification.danger(zipCodeExistsNotification);
            } else {
              storeJurisdiction.addJurisdictionZipCodeRange(id, range.zipStart, range.zipEnd)
                .then((isSucceed) => {
                  if (isSucceed) {
                    const filter = {
                      zipStart: zipCodeStartFilter === '' ? '' : zipCodeStartFilter,
                      zipEnd: zipCodeEndFilter === '' ? '' : zipCodeEndFilter,
                    };
                    const paginationZipCodeRanges = {
                      skip: pagination.skip,
                      limit: pagination.pageSize
                    };

                    if (setValue && getValues) {
                      const zipCodeRangesValues = getValues('zipCodeRanges');

                      setValue(
                        `zipCodeRanges.${zipCodeRangesValues.length}`,
                        { refid: '', jurisdiction: id, ...range },
                        { shouldDirty: true, shouldTouch: true }
                      );
                    }

                    storeJurisdiction.getJurisdictionZipCodeRanges(id, filter, paginationZipCodeRanges);
                  } else {
                    Notification.danger('An error occurred!');
                  }
                  toggleAddZipCodeRangesDialog(false);
                });
            }
          });
      }
    } else {
      toggleAddZipCodeRangesDialog(false);
    }
  };

  const onDeleteZipCodeRange = () => {
    if (isNew) {
      const filteredZipCodeRangesList =
        zipCodeRangesList.filter(
          (zipCodeRange: ZipCodeRangesType) =>
            zipCodeRange.zipStart !== zipCodeRangeToDelete.zipStart &&
            zipCodeRange.zipEnd !== zipCodeRangeToDelete.zipEnd
        );

      if (setValue && getValues) {
        const zipCodeRangesValues = getValues('zipCodeRanges');

        setValue(
          'zipCodeRanges',
          zipCodeRangesValues.filter(el =>
            el.zipStart !== zipCodeRangeToDelete.zipStart || el.zipEnd !== zipCodeRangeToDelete.zipEnd
          ),
          { shouldDirty: true, shouldTouch: true }
        );
      }

      setZipCodeRangesList(filteredZipCodeRangesList);

      toggleDeleteZipCodeRangesDialog(false);
    } else {
      if (jurisdictionZipCodesTotal > 0 || jurisdictionZipCodeRangesTotal > 1) {
        storeJurisdiction.deleteJurisdictionZipCodeRange(
          id,
          zipCodeRangeToDelete.zipStart,
          zipCodeRangeToDelete.zipEnd
        )
          .then(isSucceed => {
            if (isSucceed) {
              const filter = {
                zipStart: zipCodeStartFilter === '' ? '' : zipCodeStartFilter,
                zipEnd: zipCodeEndFilter === '' ? '' : zipCodeEndFilter,
              };
              const paginationZipCodeRanges = {
                skip: pagination.skip,
                limit: pagination.pageSize
              };

              if (setValue && getValues) {
                const zipCodeRangesValues = getValues('zipCodeRanges');

                setValue(
                  'zipCodeRanges',
                  zipCodeRangesValues.filter(el =>
                    el.zipStart !== zipCodeRangeToDelete.zipStart || el.zipEnd !== zipCodeRangeToDelete.zipEnd
                  ),
                  { shouldDirty: true, shouldTouch: true }
                );
              }

              storeJurisdiction.getJurisdictionZipCodeRanges(id, filter, paginationZipCodeRanges);
            } else {
              Notification.danger('An error occurred!');
            }

            toggleDeleteZipCodeRangesDialog(false);
          });
      } else {
        Notification.warning(
          'You can not delete the last ZIP code range! At least one ZIP code or ZIP code range is required.'
        );
      }

      toggleDeleteZipCodeRangesDialog(false);
    }
  };

  useEffect(() => {
    if (!isNew) {
      const filter = {
        zipStart: zipCodeStartFilter === '' ? '' : zipCodeStartFilter,
        zipEnd: zipCodeEndFilter === '' ? '' : zipCodeEndFilter,
      };
      const paginationZipCodeRanges = {
        skip: pagination.skip,
        limit: pagination.pageSize
      };

      storeJurisdiction.getJurisdictionZipCodeRanges(id, filter, paginationZipCodeRanges);
    }

    return storeJurisdiction.clearJurisdictionsZipCodeRanges;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNew, id, pagination]);

  useEffect(() => {
    setZipCodeRangesList(jurisdictionZipCodeRangesList);
  }, [jurisdictionZipCodeRangesList, reset]);

  return (
    <div className="row">
      {!isNew ? (
        <div className='row'>
          <PureInput
            label="From"
            type="number"
            name="zipStart"
            className="col-md-6 col-sm-6"
            value={zipCodeStartFilter}
            onChange={handleSetZipStart}
          />

          <PureInput
            label="To"
            type="number"
            name="zipEnd"
            className="col-md-6 col-sm-6"
            value={zipCodeEndFilter}
            onChange={handleSetZipEnd}
          />
        </div>
      ) : null}

      <Grid
        id="jurisdictionZipCodeRangeList"
        onAjax={fetchingZipCodeRanges}
        columns={COLUMNS}
        disablePagination={isNew}
        dataSource={zipCodeRangesList}
        dataSourceCount={jurisdictionZipCodeRangesTotal}
        pagination={pagination}
        onPaginationChange={setPagination}
        gridControlPanel={
          <GridControlButton
            title="Add"
            onClick={() => toggleAddZipCodeRangesDialog(true)}
          />
        }
      />

      {isDeleteZipCodeRangesDialogVisible && (
        <DialogConfirm
          onCancel={() => toggleDeleteZipCodeRangesDialog(false)}
          onApprove={onDeleteZipCodeRange}
        >
          Are you sure you want to delete?
        </DialogConfirm>
      )}

      {isAddZipCodeRangesDialogVisible && (
        <JurisdictionZipCodeRangeDetailsModal
          jurisdiction={id}
          callback={onZipCodeRangeDetailsCallback}
        />
      )}
    </div>
  );
};

export default observer(JurisdictionZipCodeRangesGrid);