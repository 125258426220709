import { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import Title from 'components/project/common/title';
import { SpinnerFixed } from 'components/spinner';
import Form from './components/Form';

import {
  storeZipCode,
  ZipCodeEditType,
} from 'stores/_mobx/systemSetup/masterSetting/zipCode';
import { URL_ZIP_CODES } from 'constant/path/systemSetup';

interface MatchParams {
  id: string;
}

interface PropsType extends RouteComponentProps<MatchParams> {}

const ZipCodeEditPage = ({ match: { params }, history }: PropsType) => {
  const { fetching, zipCode } = storeZipCode;

  const handleSubmit = (payload: ZipCodeEditType) =>
    storeZipCode.updateZipCode(payload).then((isSucceed) => {
      if (isSucceed) history.push(URL_ZIP_CODES);
    });

  useEffect(() => {
    storeZipCode.getZipCode(Number(params.id));

    return storeZipCode.clearZipCode;
  }, [params.id]);

  return (
    <>
      <Title title="Edit Zip code" />
      <Form defaultValues={zipCode} onSubmit={handleSubmit} />
      {fetching && <SpinnerFixed />}
    </>
  );
};

export default observer(ZipCodeEditPage);
