import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const validation = yupResolver(
  yup.object().shape({
    icdCode: yup.string().required('Please specify ICD code name'),
    shortDescription: yup
      .string()
      .required('Please specify ICD code description'),
  })
);

export default validation;
