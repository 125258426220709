import { makeObservable, observable, action } from 'mobx';

import Notification from 'components/modal/Notification';

import { apiRequest } from 'services/RequestService';
import { storeImport } from 'stores/_mobx/import';
import Pagination from 'stores/_mobx/options/pagination';

export const filterDefaultValue: FilterType = {
  locality: '',
  stateId: 0,
};

export interface FilterType {
  locality?: string;
  stateId?: number;
  zip?: string;
}

export interface ZipType {
  carrier: string;
  lab_cb_locality: string;
  locality: string;
  part_b_drug_indicator: string;
  plus_4_flag: string;
  refid: number;
  rural_index: string;
  rural_index_2: string;
  state: string;
  stateId: number;
  year_qtr: string;
  zip: string;
}

interface ZipCodesListResponseType {
  totalCount: number;
  result: ZipType[];
}

interface ImportResponseType {
  carrier: string;
  lab_cb_locality: string;
  locality: string;
  part_b_drug_indicator: null;
  plus_4_flag: string;
  rural_index: string;
  rural_index_2: null;
  state: string;
  year_qt: null;
  zip: string;
}

export interface ZipCodeEditType extends Omit<ZipType, 'state'> {}

class ZipCode {
  fetching: boolean = false;
  uploading: boolean = false;
  zipCodesList: ZipType[] = [];
  zipCodesTotal: number = 0;
  zipCode: ZipCodeEditType | undefined;
  idForDelete: number = 0;
  filter: FilterType = filterDefaultValue;

  page: Pagination = new Pagination({ id: 'jurisdictionZipCodeGrid' });

  constructor() {
    makeObservable(this, {
      uploading: observable,
      fetching: observable,
      zipCodesList: observable,
      zipCodesTotal: observable,
      zipCode: observable,
      idForDelete: observable,
      filter: observable,

      setUploading: action,
      setFetching: action,
      setZipCodes: action,
      setZipCode: action,
      setIdForDelete: action.bound,
      setFilter: action.bound,
      clearIdForDelete: action.bound,
      clearZipCode: action.bound,
      clearZipCodesList: action.bound,
    });
  }

  setUploading(inProgress: boolean) {
    this.uploading = inProgress;
  }

  setFetching(fetching: boolean) {
    this.fetching = fetching;
  }

  setZipCodes(list: ZipType[], total: number) {
    this.zipCodesList = list;
    this.zipCodesTotal = total;
  }

  setFilter(filter: FilterType) {
    this.filter = filter;
  }

  setIdForDelete(id: number) {
    this.idForDelete = id;
  }

  setZipCode(zipCode: ZipCodeEditType) {
    this.zipCode = zipCode;
  }

  clearZipCode() {
    this.zipCode = undefined;
  }

  clearIdForDelete() {
    this.idForDelete = 0;
  }

  clearZipCodesList() {
    this.zipCodesList = [];
    this.zipCodesTotal = 0;
  }

  getZipCodesList = async (props?: {
    pagination: Pagination['pagination'];
    filter: FilterType;
  }) => {
    const { pagination, filter } = props || {
      pagination: this.page.pagination,
      filter: this.filter,
    };

    this.setFetching(true);
    try {
      const reg = /(\d$)/;

      const { totalCount, result } = await apiRequest<ZipCodesListResponseType>(
        {
          url: 'codemaster.ZipCodes.zipCodes',
          data: [filter, pagination]
        }
      );

      const zipCodes = result.map((zip) => ({
        ...zip,
        year_qtr: zip.year_qtr.replace(reg, `/$1`),
      }));

      this.setZipCodes(zipCodes, totalCount);
    } catch (error) {
      this.setZipCodes([], 0);
    } finally {
      this.setFetching(false);
    }
  };

  async deleteZipCode() {
    const { idForDelete } = this;
    this.setFetching(true);
    try {
      const response = await apiRequest<number | { message: string }>({
        url: 'codemaster.ZipCodes.deleteZip',
        data: [idForDelete],
      });

      if (typeof response === 'number') {
        Notification.success(
          `ZIP code with id=${response} was deleted successfully`
        );
        this.clearIdForDelete();
        return true;
      }

      Notification.danger(
        `Cannot delete this ZIP code: ${(response as any).message}`
      );
      return false;
    } catch (error) {
      this.setFetching(false);
      return false;
    }
  }

  async uploadZipCodeFile(file: File) {
    this.setUploading(true);
    try {
      const fileName = await storeImport.uploadFile({
        file,
      });

      if (!fileName) throw Error('');

      const response = await apiRequest<ImportResponseType[] | null>({
        url: 'codemaster.ZipCodes.importZipCodes',
        data: [fileName],
      });

      if (Array.isArray(response)) {
        const zips = response.map(({ zip }) => zip).join(', ');

        if (zips) {
          Notification.danger(`Following ZIP codes were not imported: ${zips}`);
        } else {
          Notification.success(`All ZIP codes was imported successfully`);
        }
        return !zips;
      } else {
        Notification.danger(
          `Cannot import ZIP codes: ${(response as any).message}`
        );
        return false;
      }
    } catch (e: any) {
      Notification.danger('An error occurred!');
    } finally {
      this.setUploading(false);
    }
  }

  async getZipCode(id: number) {
    this.setFetching(true);
    try {
      const zip = await apiRequest<ZipType>({
        url: 'codemaster.ZipCodes.zipCode',
        data: [id],
      });
      this.setZipCode(zip);
    } catch (e: any) {
      this.clearZipCode();
    } finally {
      this.setFetching(false);
    }
  }

  async addZipCode({ refid, ...payload }: ZipCodeEditType) {
    try {
      const response = await apiRequest<number>({
        url: 'codemaster.ZipCodes.insertZip',
        data: [payload],
      });

      if (response > 0) {
        Notification.success('Zip code successfully saved!');
        return true;
      }
      throw Error('');
    } catch (e: any) {
      Notification.danger('An error occurred!');
      return false;
    }
  }

  async updateZipCode(payload: ZipCodeEditType) {
    try {
      const response = await apiRequest<number>({
        url: 'codemaster.ZipCodes.updateZip',
        data: [payload],
      });
      if (response > 0) {
        Notification.success('Zip code successfully updated!');
        return true;
      }
      throw Error('');
    } catch (e: any) {
      Notification.danger('An error occurred!');
      return false;
    }
  }
}

export const storeZipCode = new ZipCode();
