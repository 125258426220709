import { useEffect, useLayoutEffect, useMemo, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import { DropdownSalutation } from 'components/project/dropdown/SalutationDropdown';
import { DropdownSuffix } from 'components/project/dropdown/SuffixDropdown';
import radiologyOptions from 'components/project/common/RadioOptions';
import DropdownMobileOperator from 'components/project/dropdown/MobileOperator';
import { DropdownSpecialty } from 'components/project/dropdown/SpecialtyDropdown';
import { DropDownState } from 'components/project/dropdown/StateDropdown';
import { DropdownTaxonomyCode } from 'components/project/dropdown/TaxonomyCodeDropdown';
import { InputNpiExtended } from 'components/project/NpiLookupInput';
import CheckboxesEventsForSubscriptions from 'components/project/checkboxes/EventsForSubscriptions';
import { Radio } from 'components/form/radio';
import { Input, InputPassword } from 'components/form/textField';
import { InputCalendar } from 'components/form/inputCalendar';
import { FileField } from 'components/form/file';
import Collapse from 'components/collapse';
import MaskInput from 'components/form/maskInput';
import { CanvasField } from 'components/form/canvas';
import { Textarea } from 'components/form/textarea';
import Badge from 'components/badge';
import { ControlsLayout } from 'components/layout';
import { Button } from 'components/button';
import validator from './validation';
import { checkFieldsValidity } from './helpers';

import { NpiType } from 'stores/_mobx/npi';
import {
  OptionType,
  PhysicianClinicalGroupsType,
  PhysicianFormType,
  storePhysician,
} from 'stores/_mobx/clinicianManager/physician';
import { storeGroupManager } from 'stores/_mobx/clinicianManager/groupManager';
import { physicianDetailsPrettier } from 'utils/physicianPrettier';
import { URL_DATA_GOV } from 'constant/externalLinks';

const messagingOptions = [
  { label: 'Internal', value: 'I' },
  { label: 'External', value: 'E' },
  { label: 'Both', value: 'B' },
  { label: 'None', value: 'N' },
];

const physicianNotificationOptions = [
  { value: 'M', label: 'Monthly' },
  { value: 'W', label: 'Weekly' },
  { value: 'D', label: 'Daily' },
  { value: 'H', label: 'Hourly' },
];

interface PropsType {
  defaultValues: PhysicianFormType;
  fetching: boolean;
  backUrl?: string;
  passwordOptions?: {
    minLength: number;
    maxLength: number;
    alphaNumeric: boolean;
  };
  mode: 'A' | 'E',
  onSubmit: (payload: PhysicianFormType) => Promise<any>;
}

const generalInformationFields: Array<keyof PhysicianFormType> = [
  'firstName',
  'lastName',
  'gender',
  'username',
];

const loginInformationFields: Array<keyof PhysicianFormType> = [
  'username',
  'password',
  'repeatPassword'
];

const primaryPracticeAddressFields: Array<keyof PhysicianFormType> = [
  'primaryPractice',
];

const mailingAddressFields: Array<keyof PhysicianFormType> = [
  'contact',
];

const licensorFields: Array<keyof PhysicianFormType> = [
  'licensor',
];

const emailNotificationFields: Array<keyof PhysicianFormType> = [
  'emailNotifyPeriod',
  'emailNotify',
  'notificationEmail'
];

const PhysicianCompleteInformationForm = ({
  defaultValues,
  fetching,
  backUrl,
  passwordOptions,
  mode,
  onSubmit,
}: PropsType) => {
  const {
    control,
    formState: {
      isDirty,
      isSubmitting,
      errors,
    },
    handleSubmit,
    watch,
    reset,
    setValue,
    trigger,
    setError,
    getValues,
  } = useForm<PhysicianFormType>({
    defaultValues,
    resolver: validator(passwordOptions),
  });
  const maxYearDate = useRef<Date>(new Date());

  const isLoading = fetching || isSubmitting;

  const [emailNotify] = watch(['emailNotify']);

  const clinicianGroupsFieldValue = getValues('participateInGroup');
  const clinicalGroupsOptions: OptionType[] = storeGroupManager.options.filter(
    ({ value }) => clinicianGroupsFieldValue.includes(value)
  );

  const physicianGroups = storePhysician.physicianClinicalGroups.length
    ? storePhysician.physicianClinicalGroups
    : clinicalGroupsOptions;

  const handleClickReset = () => {
    reset(defaultValues);
  };

  const handleClickSubmit = handleSubmit((data: PhysicianFormType) =>
    onSubmit(data).then((error) => {
      if (error) {
        for (let key in error) {
          // @ts-ignore
          setError(key, {
            type: 'server',
            message: error[key],
          });
        }
      }
    })
  );

  const handlePhysicianSelected = (externalPhysician: NpiType) => {
    const formData = getValues();

    const physicianDetails = physicianDetailsPrettier(
      externalPhysician,
      formData
    );

    setValue('licensor.npi', physicianDetails.licensor.npi, {
      shouldDirty: true,
    });
    reset(physicianDetails, { keepDirty: true, keepIsSubmitted: true });
  };

  const collapseState = useMemo(() => ({
    generalInformation: {
      isValid: checkFieldsValidity<PhysicianFormType>({ fieldsNames: generalInformationFields, errors }),
      key: `generalInformation-${performance.now().toString(16)}`
    },
    loginInformation: {
      isValid: checkFieldsValidity<PhysicianFormType>({ fieldsNames: loginInformationFields, errors }),
      key: `loginInformation-${performance.now().toString(16)}`
    },
    primaryPracticeAddress: {
      isValid: checkFieldsValidity<PhysicianFormType>({ fieldsNames: primaryPracticeAddressFields, errors }),
      key: `primaryPractice-${performance.now().toString(16)}`
    },
    mailingAddress: {
      isValid: checkFieldsValidity<PhysicianFormType>({ fieldsNames: mailingAddressFields, errors }),
      key: `mailingAddress-${performance.now().toString(16)}`
    },
    licensor: {
      isValid: checkFieldsValidity<PhysicianFormType>({ fieldsNames: licensorFields, errors }),
      key: `licensor-${performance.now().toString(16)}`
    },
    emailNotification: {
      isValid: checkFieldsValidity<PhysicianFormType>({ fieldsNames: emailNotificationFields, errors }),
      key: `emailNotification-${performance.now().toString(16)}`
    },
  }), [errors]);

  useEffect(() => {
    const subscription = watch((formValue, { name, type }) => {
      if (
        (name === 'licensor.taxonomyCode' || name === 'licensor.specialty') &&
        type === 'change'
      ) {
        const fieldName =
          name === 'licensor.taxonomyCode'
            ? 'licensor.specialty'
            : 'licensor.taxonomyCode';

        const value =
          name === 'licensor.taxonomyCode'
            ? formValue.licensor.taxonomyCode
            : formValue.licensor.specialty;

        setValue(fieldName, value);
        trigger(fieldName);
      }
    });
    return subscription.unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch]);

  useEffect(() => {
    reset(defaultValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues]);

  useLayoutEffect(() => {
    storePhysician.checkPermission();
    storeGroupManager.checkPermission();
  }, []);

  return (
    <form className={isLoading ? 'on-ajax' : ''} onSubmit={handleClickSubmit}>
      <Radio
        name="mailType"
        label="Messaging"
        className="part-inline"
        options={messagingOptions}
        control={control}
      />

      <Collapse
        title="General Information"
        isCollapsed={false}
        key={collapseState.generalInformation.key}
        containerClassName={collapseState.generalInformation.isValid ? '' : 'invalid'}
        render={() => (
          <>
            <FileField
              name="profileImage"
              label="Profile image"
              accept="image/*"
              classNameContent="general-information-avatar"
              control={control}
            />
            <div className="row">
              <Input
                name="lastName"
                label="Last name"
                className={`col-md-6 ${mode === "A" ? "col-lg-4" : "col-lg-3"}`}
                required
                control={control}
              />
              <Input
                name="firstName"
                label="First name"
                className={`col-md-6 ${mode === "A" ? "col-lg-4" : "col-lg-3"}`}
                required
                control={control}
              />
              <Input
                name="middleName"
                label="Middle name"
                className={`col-md-6 ${mode === "A" ? "col-lg-4" : "col-lg-3"}`}
                control={control}
              />

              {mode === 'E' ? (
                <Input
                  name="username"
                  label="Username"
                  className="col-md-6 col-lg-3"
                  required
                  control={control}
                />
              ) : null}

              <div />
              <InputCalendar
                name="dateOfBirth"
                label="Date of birth"
                className="col-md-6 col-lg-3 col-xl-2"
                showYearDropdown
                scrollableYearDropdown
                yearDropdownItemNumber={90}
                maxDate={maxYearDate.current}
                control={control}
              />
              <DropdownSalutation
                name="prefix"
                label="Salutation"
                className="col-md-6 col-lg-3 col-xl-2"
                control={control}
              />
              <Radio
                name="gender"
                label="Gender"
                className="col-md-6 col-lg-4 inline"
                required
                options={radiologyOptions.normalGender}
                control={control}
              />
              <div />
              <DropdownSuffix
                name="qualification"
                label="Qualification"
                className="col-md-6 col-lg-3 col-xl-2"
                control={control}
              />
              <Input
                name="phy_group"
                label="Provider Group"
                className="col-md-6 col-lg-4"
                control={control}
              />
            </div>
          </>
        )}
      />

      {mode === 'A' ? (
        <Collapse
          title="Login Information"
          key={collapseState.loginInformation.key}
          isCollapsed={collapseState.loginInformation.isValid}
          containerClassName={collapseState.loginInformation.isValid ? '' : 'invalid'}
          render={() => (
            <div className="row">
              <Input
                name="username"
                label="Username"
                className="col-md-4"
                required
                control={control}
              />
              <div className="w-100" />
              <InputPassword
                name="password"
                label="Password"
                className="col-md-4"
                required
                control={control}
              />
              <InputPassword
                name="repeatPassword"
                label="Repeat password"
                className="col-md-4"
                required
                control={control}
              />
            </div>
          )}
        />
      ) : null}

      <Collapse
        title="Primary Practice Address"
        key={collapseState.primaryPracticeAddress.key}
        isCollapsed={collapseState.primaryPracticeAddress.isValid}
        containerClassName={collapseState.primaryPracticeAddress.isValid ? '' : 'invalid'}
        render={() => (
          <div className="row">
            <Input
              name="primaryPractice.address"
              label="Address"
              className="col-md-4"
              control={control}
            />
            <Input
              name="primaryPractice.city"
              label="City"
              className="col-md-4"
              control={control}
            />
            <Input
              name="primaryPractice.state"
              label="Office State"
              className="col-md-4"
              control={control}
            />
            <MaskInput
              name="primaryPractice.zipCode"
              label="Zip code"
              format="#####-####"
              className="col-md-6 col-lg-4"
              control={control}
            />
            <MaskInput
              name="primaryPractice.phone"
              label="Phone"
              format="###-###-####"
              className="col-md-6 col-lg-4"
              control={control}
            />
          </div>
        )}
      />

      <Collapse
        title="Mailing Address"
        key={collapseState.mailingAddress.key}
        isCollapsed={collapseState.mailingAddress.isValid}
        containerClassName={collapseState.mailingAddress.isValid ? '' : 'invalid'}
        render={() => (
          <div className="row">
            <Input
              name="contact.address"
              label="Address"
              className="col-md-6 col-lg-4"
              control={control}
            />
            <Input
              name="contact.city"
              label="City"
              className="col-md-6 col-lg-4"
              control={control}
            />
            <Input
              name="contact.state"
              label="State"
              className="col-md-6 col-lg-4"
              control={control}
            />
            <MaskInput
              name="contact.zipCode"
              label="Zip Code"
              format="#####-####"
              className="col-md-6 col-lg-4"
              control={control}
            />
            <MaskInput
              name="contact.phone"
              label="Phone"
              format="###-###-####"
              className="col-md-6 col-lg-4"
              control={control}
            />
            <div className="col-md-6 col-lg-4">
              <div className="row">
                <MaskInput
                  name="contact.mobilePhone"
                  label="Mobile phone"
                  format="###-###-####"
                  className="col-sm-auto"
                  control={control}
                />
                <DropdownMobileOperator
                  name="contact.mobileOperator"
                  label="Mobile operator"
                  className="col-sm"
                  control={control}
                />
              </div>
            </div>
            <Input
              name="contact.email"
              label="Email"
              className="col-md-6 col-lg-4"
              control={control}
            />
            <MaskInput
              name="contact.fax"
              label="Fax"
              format="###-###-####"
              className="col-md-6 col-lg-4"
              control={control}
            />
            <Input
              name="contact.emergencyName"
              label="Emergency name"
              className="col-md-6 col-lg-4"
              control={control}
            />

            <MaskInput
              name="contact.emergencyPhone"
              label="Emergency phone"
              format="###-###-####"
              className="col-md-6 col-lg-4"
              control={control}
            />
            <Input
              name="contact.spouseName"
              label="Spouse name"
              className="col-md-6 col-lg-4"
              control={control}
            />
            <Input
              name="contact.linkedinId"
              label="LinkedIn ID"
              className="col-md-6 col-lg-4"
              control={control}
            />
          </div>
        )}
      />

      <Collapse
        title="Licensor"
        key={collapseState.licensor.key}
        isCollapsed={collapseState.licensor.isValid}
        containerClassName={collapseState.licensor.isValid ? '' : 'invalid'}
        render={() => (
          <div className="row">
            <Input
              name="licensor.dea"
              label="DEA"
              className="col-md-6 col-lg-4"
              control={control}
            />
            <Radio
              name="licensor.coSignRequired"
              label="Co sign Required"
              className="col-md-6 col-lg-4 part-inline"
              options={radiologyOptions.yesNo}
              control={control}
            />
            <Radio
              name="licensor.providerStatus"
              label="Physician Status"
              className="col-md-6 col-lg-4 part-inline"
              options={radiologyOptions.active}
              control={control}
            />
            <Input
              name="licensor.website"
              label="Website"
              className="col-md-6 col-lg-4"
              control={control}
            />
            <Input
              name="licensor.providerLevel"
              label="Physician Level"
              className="col-md-6 col-lg-4"
              control={control}
            />
            <Input
              name="licensor.upin"
              label="UPIN"
              className="col-md-6 col-lg-4"
              control={control}
            />
            <Input
              name="licensor.einName"
              label="EIN Name"
              className="col-md-6 col-lg-4"
              control={control}
            />
            <Input
              name="licensor.ein"
              label="EIN"
              className="col-md-6 col-lg-4"
              control={control}
            />
            <DropdownSpecialty
              name="licensor.specialty"
              label="Specialty"
              className="col-md-6 col-lg-4"
              required
              control={control}
            />

            <Input
              name="licensor.supervisor"
              label="Supervisor"
              className="col-md-6 col-lg-4"
              control={control}
            />
            <Input
              name="licensor.cliaNumber"
              label="CLIA Number"
              className="col-md-6 col-lg-4"
              control={control}
            />
            <Radio
              name="licensor.notifySupervisor"
              label="Notify Supervisor"
              className="col-md-6 col-lg-4 part-inline"
              options={radiologyOptions.yesNo}
              control={control}
            />
            <InputNpiExtended
              name="licensor.npi"
              label="NPI"
              className="col-md-6 col-lg-4"
              required
              control={control}
              onPhysicianSelected={handlePhysicianSelected}
            />
            <Input
              name="licensor.referring"
              label="Referring Number"
              className="col-md-6 col-lg-4"
              control={control}
            />
            <div />
            <DropDownState
              name="licensor.state"
              label="State"
              className="col-md-6 col-lg-4"
              control={control}
            />
            <Input
              name="licensor.licenseNumber"
              label="License No"
              className="col-md-6 col-lg-4"
              control={control}
            />
            <DropdownTaxonomyCode
              name="licensor.taxonomyCode"
              label="Taxonomy Code"
              className="col-md-6 col-lg-4"
              required
              control={control}
            />
            <div />
            <Radio
              name="licensor.pecos"
              className="col-sm-auto part-inline"
              options={radiologyOptions.yesNo}
              required
              label={
                <>
                  PECOS
                  <Badge variant="danger" className="mx-2">
                    <a
                      href={URL_DATA_GOV}
                      target="_blank"
                      className="link-light"
                      rel="noreferrer">
                      PECOS
                    </a>
                  </Badge>
                </>
              }
              control={control}
            />
          </div>
        )}
      />

      <Collapse
        title="Upload Signature"
        render={() => <CanvasField name="signature" control={control} />}
      />

      <Collapse
        title="Email Notification"
        key={collapseState.emailNotification.key}
        isCollapsed={collapseState.emailNotification.isValid}
        containerClassName={collapseState.emailNotification.isValid ? '' : 'invalid'}
        render={() => (
          <div className="row">
            <Radio
              name="emailNotify"
              label="Physician Unsigned Notification"
              className="part-inline col-md-6"
              options={physicianNotificationOptions}
              control={control}
            />
            {emailNotify === 'H' && (
              <Input
                name="emailNotifyPeriod"
                className="col-md-4 col-lg-3 col-xl-2"
                label="Notify period(h)"
                type="number"
                min={0}
                control={control}
                required
              />
            )}
            <div />
            <Input
              name="notificationEmail"
              label="Notification Email"
              className="col-md-6"
              control={control}
            />
          </div>
        )}
      />

      <Collapse
        title="Subscribe to Email Notifications"
        render={() => (
          <div className="row">
            <CheckboxesEventsForSubscriptions
              name="emailTemplates"
              className="col-sm-12"
              userRoleId={defaultValues.userType}
              control={control}
            />
          </div>
        )}
      />

      <Collapse
        title="Comments"
        render={() => (
          <Textarea
            name="comments"
            label="Enter Your Comments"
            rows={5}
            control={control}
          />
        )}
      />

      <Collapse
        title="Clinical Group"
        render={() => (
          <div className="row">
            <div className="col-md-4">
              <p className="fw-bold">Clinical groups</p>

              {physicianGroups.length ? (
                <ul className="mb-3">
                  {physicianGroups.map((group: { label: string }, index: number) => (
                    <li key={group.label}>{`${index + 1}. ${group.label}`}</li>
                  ))}
                </ul>
              ) : (
                <p>None</p>
              )}
            </div>

            {storeGroupManager.permission.canAssignGroupAdmin && (
              <div className="col-md-4">
                <p><b>Clinical group admin</b></p>
                {storePhysician.physicianDetails.supervisedGroup && storePhysician.physicianClinicalGroups?.length ? (
                  <p>
                    {storePhysician.physicianClinicalGroups.find(
                      ({ data }: PhysicianClinicalGroupsType) => data === storePhysician.physicianDetails.supervisedGroup
                    )?.label}
                  </p>
                ) : (
                  <p>None</p>
                )}
              </div>
            )}

            <small className="fw-light">
              Please go to the Clinical Manager Edit page to add or remove
              a Physician to/from a Group.
            </small>
          </div>
        )}
      />

      <ControlsLayout alignX="between">
        {backUrl ? (
          <Link to={backUrl} className="btn btn-danger">
            Back
          </Link>
        ) : (
          <div />
        )}
        <div>
          <Button
            variant="warning"
            text="Reset"
            disabled={isLoading}
            onClick={handleClickReset}
          />
          <Button type="submit" text="Save" disabled={!isDirty || isLoading} />
        </div>
      </ControlsLayout>
    </form>
  );
};

export default PhysicianCompleteInformationForm;
