import React, { useEffect, useMemo, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';

import {
  JurisdictionFormType,
  JurisdictionModalityType
} from 'stores/_mobx/systemSetup/masterSetting/jurisdiction';

import { Button } from 'components/button';
import { Grid } from 'components/grid';
import { ControlsLayout } from 'components/layout';
import { Input, PureInput } from 'components/form/textField';
import { DropdownUsaState } from 'components/project/dropdown/UsaStateDropdown';
import Notification from 'components/modal/Notification';
import JurisdictionZipCodeRangesGrid
  from 'components/project/common/JurisdictionZipCodeRangesGrid';

import JurisdictionZipCodesGrid from '../components/JurisdictionZipCodesGrid';
import resolver from './validation';

const additionalFieldOption = {
  shouldDirty: true,
  shouldTouch: true,
};

interface PropsType {
  fetching: boolean;
  isNew: boolean;
  id?: number;
  defaultValues: JurisdictionFormType;
  onSubmit: (payload: JurisdictionFormType) => Promise<any>;
}

const JurisdictionDetailsForm = ({
  fetching,
  isNew,
  id,
  defaultValues,
  onSubmit,
}: PropsType) => {
  const {
    control,
    formState: { isDirty, isSubmitting, errors },
    setValue,
    getValues,
    reset,
    handleSubmit,
  } = useForm<JurisdictionFormType>({
    defaultValues,
    resolver,
  });

  const modalityListItems = useWatch({
    control,
    name: 'modalityList',
  });

  const [zipReset, setZipReset] = useState<boolean>(false);

  const handleClickReset = () => {
    setZipReset(true);

    reset(defaultValues);
  };

  const handleClickSubmit = handleSubmit((data: JurisdictionFormType) =>
    onSubmit(data)
  );

  useEffect(() => {
    reset(defaultValues);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues]);

  const COLUMNS = useMemo(() => [
    {
      name: 'modality',
      title: 'Modality'
    },
    {
      name: 'npinumber',
      title: 'NPI #',
      render: (npiNumber: string, modalityListItem: JurisdictionModalityType, idx: number) => {
        const errorMessage = ((errors?.modalityList as unknown) as any[])?.[idx]?.npinumber?.message || '';

        return (
          <PureInput
            name="npinumber"
            errorMessage={errorMessage}
            maxLength={10}
            value={npiNumber}
            onChange={(value) => {
              const isNum = /^\d+$/.test(value);

              if(isNum || value === '') {
                setValue(
                  `modalityList.${idx}.npinumber`,
                  value,
                  additionalFieldOption
                )
              } else {
                Notification.warning('Npi can contain only numbers!');
              }
            }}
          />
        )
      }
    },
    {
      name: 'npiname',
      title: 'NPI Name',
      render: (npiName: string, modalityListItem: JurisdictionModalityType, idx: number) => (
        <PureInput
          name="npiname"
          value={npiName}
          onChange={(value) =>
            setValue(
              `modalityList.${idx}.npiname`,
              value,
              additionalFieldOption
            )
          }
        />
      ),
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ], [errors]);

  return(
    <form
      className={`row${fetching ? ' on-ajax' : ''}`}
      onSubmit={handleClickSubmit}
    >
      <DropdownUsaState
        name="stateId"
        label="State"
        className="col-md-6"
        required
        control={control}
      />
      <Input
        name="area"
        label="Area"
        className="col-md-6"
        required
        control={control}
      />

      <Grid
        disablePagination
        noControl
        columns={COLUMNS}
        dataSource={modalityListItems}
      />

      <div className='row'>
        <div className='col-md-6'>
          <legend className='required'>ZIP codes</legend>
          <JurisdictionZipCodesGrid
            isNew={isNew}
            id={id}
            control={control}
            setValue={setValue}
            getValues={getValues}
            reset={zipReset}
          />
          {errors.zipCodes && (
            <div className='error text-danger'>{errors.zipCodes.message}</div>
          )}
        </div>

        <div className='col-md-6'>
          <legend>ZIP code ranges</legend>
          <JurisdictionZipCodeRangesGrid
            isNew={isNew}
            id={id}
            control={control}
            setValue={setValue}
            getValues={getValues}
            reset={zipReset}
          />
        </div>
      </div>

      <ControlsLayout alignX='right'>
        <Button
          variant="warning"
          text="Reset"
          disabled={fetching || isSubmitting || !isDirty}
          onClick={handleClickReset}
        />
        <Button
          type="submit"
          text="Submit"
          disabled={fetching || isSubmitting || !isDirty}
        />
      </ControlsLayout>
    </form>
  );
};

export default JurisdictionDetailsForm;