import { makeObservable, observable, action } from 'mobx';

import Notification from 'components/modal/Notification';

import {
  apiRequest,
  errorPrettier,
  ErrorType,
  JsonLdResponseType
} from 'services/RequestService';
import Pagination from 'stores/_mobx/options/pagination';
import { storeImport } from 'stores/_mobx/import';
import AbortControllerService from 'stores/_mobx/abortControllerService';

import { downloadFile } from 'utils/downloadFile';
import { BASE_URL_FILE_DIR } from 'constant/config';

export const initialValues: DiagnosisType = {
  id: null,
  icdCode: '',
  shortDescription: '',
  icdBillableStatus: '1',
  flag: 'A',
};

const initStateFilter: FilterModel = {
  code: '',
  description: '',
  status: 'A',
  type: 'Y',
  billable: '1',
};

export interface DiagnosisType {
  id: number;
  icdCode: string;
  shortDescription: string;
  icdBillableStatus: string;
  flag: 'A' | 'I';
}

export interface FilterModel {
  code: string;
  description: string;
  status: 'A' | 'I';
  type: 'N' | 'Y';
  billable: '1' | '0';
}

interface UpdateCodesResponseType {
  imported: number;
  removed: number;
  updated: number;
  failed: number;
  errors: any[];
  error: string;
}

class DiagnosisCode extends AbortControllerService {
  fetching: boolean = false;
  uploadingInProgress: boolean = false;
  code?: DiagnosisType = undefined;
  codesList: DiagnosisType[];
  codesTotal: number = 0;
  filter: FilterModel = initStateFilter;

  page: Pagination;

  constructor() {
    super();

    this.page = new Pagination({ id: 'diagnosis_codes_grid' });

    makeObservable(this, {
      fetching: observable,
      uploadingInProgress: observable,
      codesList: observable,
      codesTotal: observable,
      code: observable,
      filter: observable,

      setFetching: action.bound,
      setUploadingProgress: action.bound,
      setCode: action.bound,
      setCodesCollection: action.bound,
      setFilter: action.bound,
      clearCode: action.bound,
    });
  }

  setFetching(fetching: boolean) {
    this.fetching = fetching;
  }

  setUploadingProgress(fetching: boolean) {
    this.uploadingInProgress = fetching;
  }

  setFilter(values: FilterModel) {
    this.filter = values;
  }

  setCodesCollection({ items, totalItems }: JsonLdResponseType<DiagnosisType>) {
    this.codesList = items;
    this.codesTotal = totalItems;
  }

  setCode(code?: DiagnosisType) {
    this.code = code;
  }

  clearCode() {
    this.code = undefined;
  }

  async getCodesList() {
    const {
      filter,
      page: { pagination },
    } = this;

    const data = {
      icdCode: filter.code,
      shortDescription: filter.description,
      icdBillableStatus: filter.billable,
      flag: filter.status,
      page: pagination.page,
      itemsPerPage: pagination.pageSize,
    };

    const abortSignal = this.manageAbortController();

    this.setFetching(true);

    try {
      const response = await apiRequest<JsonLdResponseType<DiagnosisType>>({
        url: 'icd_codes',
        method: 'GET',
        contentType: 'ld',
        legacy: false,
        signal: abortSignal,
        data,
      });

      const codes = response?.items?.map(({ id, ...code }: DiagnosisType) => ({
        ...code,
        id: Number(id),
      }));

      this.setCodesCollection({items: codes, ...response});
    } catch (e) {
      this.setCodesCollection({ totalItems: 0, items: [] });
    } finally {
      if (!abortSignal.aborted) {
        this.setFetching(false);

        this.clearAbortController();
      }
    }
  }

  async getCode(id: string | number) {
    this.setFetching(true);

    try {
      const code = await apiRequest<DiagnosisType>({
        url: `icd_codes/${id}`,
        method: 'GET',
        legacy: false,
      });

      this.setCode({ ...code, id: Number(code.id) });
    } catch (e) {
      Notification.danger("ICD code you've requested doesn't exist!");
      this.setCode(initialValues);
    } finally {
      this.setFetching(false);
    }
  }

  async addCode(payload: Omit<DiagnosisType, 'id'>) {
    const data = {
      icdCode: payload.icdCode,
      shortDescription: payload.shortDescription,
      icdBillableStatus: payload.icdBillableStatus,
      flag: 'A',
    };

    try {
      await apiRequest<DiagnosisType>({
        url: 'icd_codes',
        legacy: false,
        data,
      });

      Notification.success('ICD Code was added successfully!');

      return null;
    } catch (e: any) {
      const errors = errorPrettier(e as ErrorType<DiagnosisType>);

      if (!errors) Notification.danger('An error occurred!');

      return errors;
    }
  }

  async updateCode(payload: DiagnosisType) {
    const data = {
      icdCode: payload.icdCode,
      shortDescription: payload.shortDescription,
      icdBillableStatus: payload.icdBillableStatus,
    };

    try {
      await apiRequest<DiagnosisType>({
        url: `icd_codes/${payload.id}`,
        method: 'PATCH',
        legacy: false,
        data,
      });

      Notification.success('ICD Code was updated successfully!');
      return null;
    } catch (e: any) {
      const errors = errorPrettier(e as ErrorType<DiagnosisType>);

      if (!errors) Notification.danger('An error occurred!');

      return errors;
    }
  }

  async changeCodeStatus(ids: string[], state: 'A' | 'I') {
    this.setFetching(true);

    try {
      const codeIds = ids?.map(id => Number(id));

      const data = {
        ids: codeIds,
        action: state === 'I' ? "activate" : "deactivate"
      }

      await apiRequest<string>({
        url: 'icd_codes/change-status',
        legacy: false,
        data,
      });

      const statusMsg = state === 'I' ? 'activated' : 'deactivated';

      Notification.success(
        `${ids.length} ICD ${
          ids.length > 1 ? 'codes were' : 'code was'
        } ${statusMsg} successfully!`
      );

      return true;
    } catch (e) {
      Notification.danger('An error occurred!');
      return false;
    } finally {
      this.setFetching(false);
    }
  }

  async exportCodes(ids: string[]) {
    try {
      const fileName = await apiRequest<'SE' | 'E' | string>({
        url: 'codemaster.ICD9Code.ExportICDBySelected',
        data: [ids],
      });

      if (fileName !== 'S' && fileName !== 'E') {
        Notification.success(
          `${ids.length} ICD ${
            ids.length > 1 ? 'codes were' : 'code was'
          } exported!`
        );
        downloadFile(`${BASE_URL_FILE_DIR}doc_img/billingexport/${fileName}`);

        return true;
      }
      throw Error('');
    } catch (e) {
      Notification.danger('An error occurred!');
      return false;
    }
  }

  async importCodes(file: File) {
    this.setUploadingProgress(true);
    try {
      const fileName = await storeImport.uploadFile({
        url: 'temppath/tempImportUpload.php',
        file,
      });

      if (!fileName) throw Error('');

      const result = await apiRequest<'SE' | 'S' | 'E'>({
        url: 'codemaster.ICD9Code.ImportICDCode',
        data: [fileName],
      });

      if (result === 'S') {
        Notification.success('Diagnosis codes were imported!');
        return true;
      }
      throw Error('');
    } catch (e) {
      Notification.danger('An error occurred!');
      return false;
    } finally {
      this.setUploadingProgress(false);
    }
  }

  async updateCodes(file: File) {
    this.setUploadingProgress(true);

    try {
      const response = await storeImport.uploadFileNewApi<UpdateCodesResponseType>({
        url: 'icd_codes/import',
        payload: { file },
      });

      if ('imported' in response && 'updated' in response && 'removed' in response) {
        Notification.success(
          `Diagnosis codes were updated:
              ${response.imported} ${response.imported === 1 ? 'code was' : 'codes were'} imported,
              ${response.removed} ${response.removed === 1 ? 'code was' : 'codes were'} removed,
              ${response.updated} ${response.updated === 1 ? 'code was' : 'codes were'} updated.`,
          {
            style: { whiteSpace: 'pre-line' },
            autoClose: 10000,
          }
        );

        return true;
      } else if ('error' in response) {
        Notification.danger(response.error);

        return false;
      }

      return true;
    } catch (e) {
      Notification.danger('An error occurred!');
      return false;
    } finally {
      this.setUploadingProgress(false);
    }
  }
}

export const storeDiagnosisCode = new DiagnosisCode();
