import { useState } from 'react';

import { Button } from 'components/button';
import Dialog, {
  DialogBody,
  DialogFooter,
  DialogHeader,
} from 'components/modal/dialog';
import { PureFile } from 'components/form/file';

interface PropsType {
  fetching: boolean;
  onClose: () => void;
  onSubmit: (file: File) => void;
}

const DialogUpdate = ({ fetching, onClose, onSubmit }: PropsType) => {
  const [file, setFile] = useState<File | string>('');

  const handleClickSubmit = () => {
    onSubmit(file as File);
  };

  return (
    <Dialog size="large" handleClose={onClose}>
      <DialogHeader title="Update Diagnosis codes" onClose={onClose} />
      <DialogBody className={fetching ? 'on-ajax' : ''}>
        <div className="row align-items-end justify-content-between">
          <div className="col-sm-8 col-md-4 col-lg-3">
            <PureFile
              accept=".txt"
              name="uploader"
              label="TXT file"
              required
              value={file}
              onChange={setFile}
            />
          </div>
          <div className="col-lg-9">
            <p>
              An ICD Update file can be found at &nbsp;
              <a
                href="https://www.cms.gov/medicare/coding-billing/icd-10-codes"
                className="text-primary text-nowrap"
                target="_blank"
                rel="noreferrer"
              >
                CMS.gov
              </a>&nbsp;
              under the section "ICD-10 Files".
            </p>
            <p>Download the zip archive under the name <strong>"20XX Code Descriptions in Tabular Order (ZIP)"</strong> and unzip it. Choose the file for the current year.</p>
            <p>Choose the file with the name <strong>"icd10cm_codes_addenda_20XX.txt"</strong> and submit. This will update is required every year.</p>
          </div>
        </div>
      </DialogBody>
      <DialogFooter>
        <Button text="Submit" disabled={!file} onClick={handleClickSubmit} />
      </DialogFooter>
    </Dialog>
  );
};

export default DialogUpdate;
