import { RouteComponentProps } from 'react-router-dom';

import Title from 'components/project/common/title';
import DiagnosisForm from './components/Form';

import { DiagnosisType, storeDiagnosisCode } from 'stores/_mobx/systemSetup/codeSet/diagnosisCodes';
import { URL_DIAGNOSIS_CODES } from 'constant/path/systemSetup';

interface PropsType extends RouteComponentProps {}

const DiagnosisCodesAddPage = ({ history }: PropsType) => {
  const handleSubmit = (payload: DiagnosisType) =>
    storeDiagnosisCode.addCode(payload).then((error) => {
      if (error) return error;
      history.push(URL_DIAGNOSIS_CODES);
    });

  return (
    <>
      <Title title="Create new ICD code" />
      <DiagnosisForm backUrl={URL_DIAGNOSIS_CODES} onSubmit={handleSubmit} />
    </>
  );
};

export default DiagnosisCodesAddPage;
