import { memo, useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { Input } from 'components/form/textField';
import RadioOptions from 'components/project/common/RadioOptions';
import {
  storeDiagnosisCode,
  FilterModel,
} from 'stores/_mobx/systemSetup/codeSet/diagnosisCodes';
import { Radio } from 'components/form/radio';
import { OPTIONS_IS_BILLABLE } from './formOptions';

interface PropsType {
  onChange: (filter: FilterModel) => void;
}

const DiagnosisCodesFilter = ({ onChange }: PropsType) => {
  const { control, getValues, watch } = useForm<FilterModel>({
    defaultValues: storeDiagnosisCode.filter,
  });

  const handleFormChange = () => {
    const values = getValues();

    storeDiagnosisCode.setFilter(values);
  };

  useEffect(() => {
    const subscription = watch((formValue, { name }) => {
      onChange(formValue as FilterModel);
    });
    return subscription.unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch]);

  return (
    <form className="row" onChange={handleFormChange}>
      <Input
        name="code"
        label="ICD Code"
        className="col-sm-4"
        control={control}
      />
      <Input
        name="description"
        label="Description"
        className="col-sm-4"
        control={control}
      />
      <Radio
        name="status"
        label=" "
        className="col-sm-4 part-inline"
        options={RadioOptions.active}
        control={control}
      />

      <Radio
        name="billable"
        className="col-sm-4 inline"
        options={OPTIONS_IS_BILLABLE}
        control={control}
      />
    </form>
  );
};

export default memo(DiagnosisCodesFilter);
