import { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';

import DialogMessagesInvoice from 'page/components/messages/DialogInvoice';
import DialogTransmitLog from 'page/billing-coding/components/DialogTransmitLog';
import BillingAddress from 'page/billing-coding/components/BillingAddress';
import {
  InvoicePdfExportLink,
  InvoiceXlsxExportLink,
} from 'page/components/ExportLink';
import { Grid, GridControlButton } from 'components/grid';
import { IconButton } from 'components/button';
import Exporter from 'components/project/ExporterNew';
import DialogConfirm from 'components/modal/dialogConfirm';
import Filter from './components/Filter';
import DialogAuditLog from './components/DialogAuditLog';

import {
  storeBillAddress,
  BillAddressFormType,
} from 'stores/_mobx/billingCodding/billAddress';
import {
  storeInvoice,
  InvoiceType,
  FilterType,
} from 'stores/_mobx/billingCodding/invoice';
import UserProfileStore from 'stores/UserProfileStore';
import Pagination from 'stores/_mobx/options/pagination';

const TOTAL_COLUMNS = [
  { name: 'totalPrice', title: 'Total Amount' },
  { name: 'paid', title: 'Paid Amount' },
  { name: 'balance', title: 'Due Amount' },
  { name: 'overpaid', title: 'Over Paid' },
  { name: 'discount', title: 'Discount' },
  { name: 'writeoff', title: 'Write Off' },
];

const priceCell = (value: number) => `$ ${value.toFixed(2)}`;

const getColumns = ({
  isClient,
  invoiceFlag,
  isPerDiemTab,
  isDivisionEnabled,
}: CellGeneratorPropsType) => {
  const columns = [
    isClient
      ? null
      : {
          name: 'chargePosting',
          title: 'Charge Posting',
          export: { exclude: true },
          render: (v: undefined, invoice: InvoiceType) => (
            <IconButton
              onClick={() => {
                storeInvoice.setInvoiceForChargePosting(invoice);
              }}>
              <i className="icon icon-open3" />
            </IconButton>
          ),
        },
    {
      name: 'billingAddress',
      title: 'Billing Address',
      export: { exclude: true },
      render: (_: undefined, order: InvoiceType) => (
        <IconButton
          className="text-primary"
          onClick={() => {
            storeInvoice.setPropsForBilling(order);
          }}>
          <i className="bi bi-building" />
        </IconButton>
      ),
    },
    { name: 'facilitynm', title: 'Facility' },
    isDivisionEnabled ? { name: 'division', title: 'Division' } : null,
    { name: 'region', title: 'Region' },
    {
      name: 'aging',
      title: 'Aged Days',
      className: 'text-center',
    },
    {
      name: 'total_price',
      title: 'Total Amount',
      export: { target: 'render' },
      render: priceCell,
    },
    {
      name: 'total_paid',
      title: 'Paid Amount',
      export: { target: 'render' },
      render: priceCell,
    },
    {
      name: 'writeoff',
      title: 'Write Off',
      export: { target: 'render' },
      render: priceCell,
    },
    {
      name: 'balance',
      title: 'Due Amount',
      export: { target: 'render' },
      render: priceCell,
    },
    {
      name: 'overpaid',
      title: 'Over Paid',
      export: { target: 'render' },
      render: priceCell,
    },
    {
      name: 'discount',
      title: 'Discount',
      export: { target: 'render' },
      render: priceCell,
    },
    {
      name: 'invoiceDetail',
      title: 'Invoice Detail',
      className: 'text-center width-100',
      export: { exclude: true },
      render: (_: any, invoice: InvoiceType) => {
        const invoiceDetail = {
          invoiceNumber: invoice.invoiceNumber,
          facilityId: Number(invoice.facilityId),
          isReceived: false,
          date: invoice.invoice_dt,
        };

        return UserProfileStore.isClientUser() ? (
          <a
            href="/"
            target="_blank"
            rel="noreferrer"
            className="fs-5"
            onClick={(e: React.SyntheticEvent) => {
              e.preventDefault();
              storeInvoice.setPropsForDownloadingInvoice(invoice);
            }}>
            <i className="bi bi-filetype-pdf" />
          </a>
        ) : (
          <InvoicePdfExportLink
            key={invoice.invoiceNumber}
            perDiemType={isPerDiemTab}
            invoiceDetail={invoiceDetail}
          />
        );
      },
    },
    {
      name: 'invoiceReport',
      title: 'Invoice Export',
      className: 'text-center width-100',
      export: { exclude: true },
      render: (_: undefined, invoice: InvoiceType) => {
        const invoiceDetail = {
          invoice_no: invoice.invoiceNumber,
          facilityId: invoice.facilityId,
          invoice_dt: invoice.invoice_dt,
          flag: invoiceFlag,
        };

        return (
          <InvoiceXlsxExportLink
            key={invoice.invoiceNumber}
            invoiceDetail={invoiceDetail}
          />
        );
      },
    },
    { name: 'invoice_dt', title: 'Invoice Date', className: 'text-nowrap' },
    { name: 'invoice_alphanum', title: 'Invoice #', className: 'text-nowrap' },
    {
      name: 'faxLog',
      title: 'Fax Log',
      className: 'text-center',
      export: { exclude: true },
      render: (_: undefined, invoice: InvoiceType) => (
        <IconButton
          onClick={() => {
            storeInvoice.setPropsForFaxing(invoice);
          }}>
          <i className="icon icon-view-item" />
        </IconButton>
      ),
    },
    {
      name: 'invoiceMessage',
      title: 'Invoice Message',
      className: 'width-75 text-center',
      export: { exclude: true },
      render: (_: undefined, { invoiceNumber, hasLog }: InvoiceType) => (
        <IconButton
          className="text-primary"
          onClick={() => {
            storeInvoice.setIdForMessages(invoiceNumber);
          }}>
          <i
            className={`bi ${
              hasLog ? 'bi-envelope-check-fill' : 'bi-envelope'
            }`}
          />
        </IconButton>
      ),
    },
    {
      name: 'fax',
      title: 'Fax',
      export: { exclude: true },
      render: (_: undefined, { facilityId, invoiceNumber }: InvoiceType) => (
        <IconButton
          className="text-primary"
          onClick={() => {
            storeInvoice.generateFacilityInvoicePDF(facilityId, invoiceNumber);
          }}>
          <i className="bi bi-printer" />
        </IconButton>
      ),
    },
    isClient
      ? null
      : {
          name: 'invoiceAuditLog',
          title: 'Audit Log',
          className: 'width-50 text-center',
          export: { exclude: true },
          render: (_: undefined, { invoiceNumber }: InvoiceType) => (
            <IconButton
              onClick={() =>
                storeInvoice.setInvoiceNumberForAudit(invoiceNumber)
              }>
              <i className="icon icon-auditlog" />
            </IconButton>
          ),
        },
  ];
  return columns.filter(Boolean);
};

interface PropsType {
  isFilterVisible: boolean;
  invoiceType: 'H' | 'N' | 'Y';
  invoiceFlag: 'P' | 'F';
  isPerDiemTab: boolean;
  isDivisionEnabled: boolean;
  page: Pagination;
}

interface CellGeneratorPropsType {
  isClient: boolean;
  isPerDiemTab: boolean;
  invoiceFlag: 'P' | 'F';
  isDivisionEnabled: boolean;
}

const InvoiceBasicTab = ({
  isFilterVisible,
  invoiceType,
  invoiceFlag,
  isPerDiemTab,
  isDivisionEnabled,
  page,
}: PropsType) => {
  const gridRef = useRef<Grid>();

  const { pagination, setPagination, setPaginationToStart } = page;

  const {
    fetching,
    invoicesList,
    invoicesTotal,
    totalSumData,
    propsForBilling,
    invoiceNumberForAudit,
    propsForDownloadingInvoice,
    propsForFaxing,
    idForMessages,
  } = storeInvoice;

  const [selected, setSelected] = useState<number[]>([]);

  const [isExportOpened, toggleExport] = useState<boolean>(false);

  const columns = useMemo(
    () =>
      getColumns({
        isClient: UserProfileStore.isClientUser(),
        invoiceFlag,
        isPerDiemTab,
        isDivisionEnabled,
      }),
    [invoiceFlag, isPerDiemTab, isDivisionEnabled]
  );

  const handleToggleExport = () => {
    toggleExport((state) => !state);
  };

  const handleCloseMessages = (shouldUpdate: boolean) => {
    storeInvoice.clearIdForMessages();
    if (shouldUpdate)
      storeInvoice.getInvoicesListMain({ invoiceType, isDivisionEnabled, page });
  };

  const handleSubmitBillAddress = (data: BillAddressFormType) => {
    const { facilityId, invoiceNumber } = propsForBilling;
    const addressflag = data.addressVariant;

    const address = data[addressflag];

    const payload = {
      facilityId,
      invoiceno: invoiceNumber,
      addressflag,
      ...address,
    };
    return storeBillAddress.updateBillAddress(payload).then((isSucceed) => {
      if (isSucceed) storeInvoice.clearPropsForBilling();
    });
  };

  const handleCloseDialogConfirmClientInvoice = () => {
    const payload = { isReceived: false, isPerDiemType: isPerDiemTab };

    storeInvoice
      .downloadInvoiceDetail(payload)
      .then(storeInvoice.clearPropsForDownloadingInvoice);
  };

  const handleConfirmClientInvoice = () => {
    const payload = { isReceived: true, isPerDiemType: isPerDiemTab };

    storeInvoice
      .downloadInvoiceDetail(payload)
      .then(storeInvoice.clearPropsForDownloadingInvoice);
  };

  const handleChangeFilter = (filter: FilterType) => {
    storeInvoice.setFilter(filter);

    setPaginationToStart();
  };

  useLayoutEffect(() => {
    storeInvoice.setDefaultFilter();

    return () => {
      storeInvoice.clearInvoiceList();
      storeInvoice.clearInvoiceForChargePosting();
    };
  }, []);

  useEffect(() => {
    storeInvoice.getInvoicesListMain({ invoiceType, isDivisionEnabled, page });
  }, [pagination, isDivisionEnabled, invoiceType, page]);

  return (
    <>
      <Filter
        fetching={fetching}
        isVisible={isFilterVisible}
        onSubmit={handleChangeFilter}
      />

      <Grid
        dataSource={totalSumData}
        wrapperClass="col-lg-8 col-xl-6"
        disablePagination
        noControl
        columns={TOTAL_COLUMNS}
      />

      <Grid
        id="invoice_grid"
        selectId="invoiceNumber"
        ref={gridRef}
        onAjax={fetching}
        pagination={pagination}
        dataSource={invoicesList}
        dataSourceCount={invoicesTotal}
        columns={columns}
        selectedIds={selected}
        onPaginationChange={setPagination}
        onSelectChange={setSelected}
        gridControlPanel={
          <GridControlButton title="Export" onClick={handleToggleExport} />
        }
      />

      {propsForBilling && (
        <BillingAddress
          facilityId={propsForBilling.facilityId}
          invoiceno={propsForBilling.invoiceNumber}
          onClose={storeInvoice.clearPropsForBilling}
          onSubmit={handleSubmitBillAddress}
        />
      )}

      {idForMessages ? (
        <DialogMessagesInvoice
          id={idForMessages}
          onClose={handleCloseMessages}
        />
      ) : null}

      {propsForFaxing && (
        <DialogTransmitLog
          details={propsForFaxing}
          onClose={storeInvoice.clearPropsForFaxing}
        />
      )}

      {isExportOpened && (
        <Exporter
          reportName="FacilityInvoices"
          exporter="InvoiceExporter"
          columns={columns}
          gridRef={gridRef}
          dataSource={invoicesList}
          filter={storeInvoice.filter}
          filterForRequest={[
            storeInvoice.filter.facility,
            'N',
            storeInvoice.filter.corporateId,
            storeInvoice.filter.state,
            storeInvoice.filter.orderType,
            storeInvoice.filter.location,
            storeInvoice.filter.pos,
            storeInvoice.filter.invoiceDateFrom,
            storeInvoice.filter.invoiceDateTo,
            '',
            storeInvoice.filter.claim,
            storeInvoice.filter.invoice,
            invoiceType,
            storeInvoice.filter.providerType,
            storeInvoice.filter.invoicesStatus,
            0,
            invoicesTotal,
            '',
            storeInvoice.filter.region,
            isDivisionEnabled ? storeInvoice.filter.division : 0,
          ]}
          onClose={handleToggleExport}
        />
      )}

      {propsForDownloadingInvoice && (
        <DialogConfirm
          onCancel={handleCloseDialogConfirmClientInvoice}
          onApprove={handleConfirmClientInvoice}>
          You are requesting to open INV#
          {` ${propsForDownloadingInvoice.invoice_alphanum}`}, do you want
          LatticePro to mark this invoice as received?
        </DialogConfirm>
      )}

      {invoiceNumberForAudit ? (
        <DialogAuditLog
          includePayment
          invoiceId={invoiceNumberForAudit}
          onClose={storeInvoice.clearInvoiceNumberForAudit}
        />
      ) : null}
    </>
  );
};

export default observer(InvoiceBasicTab);
