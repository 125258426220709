import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import { ControlsLayout } from 'components/layout';
import { Button } from 'components/button';
import { Input } from 'components/form/textField';
import { Radio } from 'components/form/radio';
import { Textarea } from 'components/form/textarea';
import { OPTIONS_IS_BILLABLE } from './formOptions';
import resolver from './validation';
import { DiagnosisType } from 'stores/_mobx/systemSetup/codeSet/diagnosisCodes';
import { ServerFormValidationType } from 'types';

interface PropsType {
  backUrl: string;
  defaultValues?: DiagnosisType;
  onSubmit: (data: DiagnosisType) => Promise<any>;
}

const initialValues: DiagnosisType = {
  id: 0,
  icdBillableStatus: '1',
  icdCode: '',
  shortDescription: '',
  flag: 'A',
};

const DiagnosisForm = ({
  defaultValues = initialValues,
  backUrl,
  onSubmit,
}: PropsType) => {
  const {
    control,
    formState: { isSubmitting, isDirty },
    handleSubmit,
    setError,
    reset,
  } = useForm<DiagnosisType>({ defaultValues, resolver });

  const handleReset = () => {
    reset(defaultValues);
  };

  const handleClickSubmit = handleSubmit((data: DiagnosisType) =>
    onSubmit(data).then((errors: ServerFormValidationType<DiagnosisType>[]) => {
      if (errors) {
        errors.forEach(({ field, ...body }) => {
          setError(field, body);
        });
      }
    })
  );

  useEffect(() => {
    handleReset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues]);

  return (
    <form
      onSubmit={handleClickSubmit}
      className={`col-lg-6${isSubmitting ? ' on-ajax' : ''}`}
    >
      <Radio
        name="icdBillableStatus"
        label="Bill status"
        className="inline"
        options={OPTIONS_IS_BILLABLE}
        control={control}
      />

      <Input name="icdCode" label="ICD Code" required control={control} />
      <Textarea
        name="shortDescription"
        label="Description"
        rows={8}
        required
        control={control}
      />

      <ControlsLayout alignX="right">
        <Link to={backUrl} className="btn btn-danger">
          Back
        </Link>
        <Button
          text="Reset"
          variant="warning"
          disabled={isSubmitting}
          onClick={handleReset}
        />
        <Button type="submit" text="Save" disabled={isSubmitting || !isDirty} />
      </ControlsLayout>
    </form>
  );
};

export default DiagnosisForm;
