import { useMemo } from 'react';

import Notification from 'components/modal/Notification';
import FormLineInvoiceMode, {
  LineInvoiceFormType,
} from './FormInvoiceLineMode';
import { modeOfPaymentRequiresNotes } from './validation';

import { TOrderNewPayItem } from 'services/billing-coding/InvoicesService';
import { TFacilityData } from 'services/billing-coding/FacilityPreviewService';
import { InvoiceType } from 'stores/_mobx/billingCodding/invoice';
import { storeInvoiceChargePosting } from 'stores/_mobx/billingCodding/invoiceChargePosting';

interface FinalPriceCollectionType {
  totalFee: number;
  totalCharge: number;
  totalDiscount: number;
  totalAmount: number;
}

interface PropTypes {
  modeOfPayment: number;
  notes: string;
  fees: {
    lateFee: number;
    lateFeePaid: number;
  };
  basicInvoiceDetails: InvoiceType;
  invoices: TOrderNewPayItem[];
  perDiemDetails: TFacilityData | null;
  flag: 'F' | 'P';
  onSubmitSucceed: () => void;
  setNotesErrorMessage: (errors: Record<string, string>) => void;
}

const WrapperFormInvoiceLineMode = ({
  fees,
  basicInvoiceDetails,
  invoices,
  perDiemDetails,
  notes,
  modeOfPayment,
  flag,
  onSubmitSucceed,
  setNotesErrorMessage,
}: PropTypes) => {
  const invoiceFinalPrice: LineInvoiceFormType = useMemo(() => {
    const result = invoices.reduce(
      (prev: FinalPriceCollectionType, current: any) => {
        prev.totalAmount += Number(current.payment) + Number(current.writeoff);
        prev.totalFee += Number(current.fee_schdule);
        prev.totalDiscount += Number(current.discount);
        prev.totalCharge += Number(current.cpt_price_val);
        return prev;
      },
      {
        totalFee: 0,
        totalCharge: 0,
        totalDiscount: 0,
        totalAmount: 0,
      }
    );

    const perDiemData: any[] = perDiemDetails?.perdiem_rptrvalue || null;

    const perDiemPrice = perDiemData
      ? perDiemData.reduce(
          (sum: number, current) =>
            (sum += Number(current.payment) + Number(current.writeoff)),
          0
        )
      : 0;

    const totalBalance =
      Number(basicInvoiceDetails.total_price) -
      result.totalAmount +
      (fees.lateFee - fees.lateFeePaid) -
      perDiemPrice;

    return {
      feeSchedule: result.totalFee,
      discount: result.totalDiscount,
      bal: result.totalCharge,
      payment: Number(basicInvoiceDetails.total_price),
      modeOfPayment,
      notes,
      total: totalBalance,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notes, modeOfPayment, invoices, fees, perDiemDetails]);

  const handleSubmitInvoice = async (formData: LineInvoiceFormType) => {
    const invoiceErrors: Record<string, string> = {};
    let isValidInvoices = true;

    invoices.forEach((invoice) => {
      const isNoteRequire = modeOfPaymentRequiresNotes.includes(
        Number(invoice.pay_mode)
      );
      const isValid = isNoteRequire ? Boolean(invoice.pay_desc.trim()) : true;

      invoiceErrors[`${invoice.studyId}_${invoice.cpt_code_val}`] = isValid
        ? ''
        : "Can't be empty!";

      if (!isValid) isValidInvoices = false;
    });

    setNotesErrorMessage(invoiceErrors);
    if (!isValidInvoices) {
      Notification.danger('Please, fill in all required fields!');
      return Promise.reject();
    }

    // @ts-ignore
    const { mode } = invoices?.[0] || 'A';

    const perDiemData = perDiemDetails?.perdiem_rptrvalue || [];

    const finalInvoices = invoices.map((invoice) => {
      const linePostBalance =
        Number(invoice.cpt_price_val) -
        (Number(invoice.payment) + Number(invoice.writeoff));

      return {
        ...invoice,
        linepost_balance: Number(linePostBalance).toFixed(2),
      };
    });

    const payload = {
      invoiceId: basicInvoiceDetails.invoiceNumber,
      invoices: finalInvoices,
      total: invoiceFinalPrice.total,
      notes: formData.notes,
      payMode: null as null,
      perDiemData,
      mode,
      facilityId: basicInvoiceDetails.facilityId,
      invoiceType: flag,
      lateFee: fees,
    };

    return (
      storeInvoiceChargePosting
        // @ts-ignore
        .addLinePayment(payload)
        .then((isSucceed) => {
          if (isSucceed) onSubmitSucceed();
        })
    );
  };

  return (
    <FormLineInvoiceMode
      defaultValues={invoiceFinalPrice}
      onSubmit={handleSubmitInvoice}
    />
  );
};

export default WrapperFormInvoiceLineMode;
