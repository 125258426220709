import MathUtils from 'utils/MathUtils';

export default class InvoiceUtils {
  static getPaidRender(invoice: any) {
    return '$ ' + MathUtils.parseFloat(this.getPaid(invoice), 2);
  }

  static getPaid(invoice: any) {
    if (invoice.total_paid && invoice.total_paid !== '0.00') {
      return MathUtils.parseFloat(invoice.total_paid);
    } else if (invoice.paid) {
      return MathUtils.parseFloat(invoice.paid);
    }
    return 0;
  }
}
