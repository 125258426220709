import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { phoneValidator, zipValidator } from 'utils/formValidators';

const phoneNumberValidation = yup.string().test({
  test: (phone: string) => (phone ? phoneValidator(phone) : true),
  message: 'Should be in xxx-xxx-xxxx format!',
});

const zipCodeValidation = yup.string().test({
  test: (zipCode: string) => (zipCode ? zipValidator(zipCode) : true),
  message: 'Should be in xxxxx-xxxx format!',
});

const emailErrorMessage = 'Invalid email address!';

interface PropsType {
  minLength: number;
  maxLength: number;
  alphaNumeric: boolean;
}

const validation = (props: PropsType) =>
  yupResolver(
    yup.object().shape({
      firstName: yup.string().required('Please fill in First Name!'),
      lastName: yup.string().required('Please fill in Last Name!'),
      gender: yup.string().required('Please select a Gender!'),
      username: yup.string().required('Please fill in an Username!'),
      password: props && yup
        .string()
        .required('Please fill in Password!')
        .test({
          test: (password: string) =>
            password.length >= props.minLength &&
            password.length <= props.maxLength,
          message: `Password length must be between ${props.minLength} and ${props.maxLength}`,
        })
        .test({
          test: (password: string) =>
            props.alphaNumeric && password.length
              ? /(\d[a-z])|([a-z]\d)/i.test(password)
              : true,
          message: 'Password should include at least 1 letter and 1 number',
        }),
      repeatPassword: props && yup
        .string()
        .required('Please repeat Password!')
        .oneOf([yup.ref('password'), null], 'Password did not match'),
      notificationEmail: yup.string().email(emailErrorMessage),
      emailNotifyPeriod: yup.number().when('emailNotify', {
        is: 'H',
        then: yup
          .number()
          .typeError('Please set period of notifications more than 0!')
          .positive('Please set period of notifications more than 0!'),
      }),
      licensor: yup.object().shape({
        specialty: yup.number().min(1, 'Please select a Specialty!'),
        taxonomyCode: yup.number().min(1, 'Please select a Taxonomy Code!'),
        pecos: yup.string().required('Please select a PECOS status!'),
        npi: yup
          .string()
          .required('Please fill in a NPI!')
          .test({
            test: (npi: string) => /^\d{10}$/.test(npi),
            message: 'NPI should contain 10 numbers!',
          }),
      }),
      primaryPractice: yup.object().shape({
        zipCode: zipCodeValidation,
        phone: phoneNumberValidation,
      }),
      contact: yup.object().shape({
        zipCode: zipCodeValidation,
        phone: phoneNumberValidation,
        mobilePhone: phoneNumberValidation,
        fax: phoneNumberValidation,
        emergencyPhone: phoneNumberValidation,
        email: yup.string().email(emailErrorMessage),
      }),
    })
  );

export default validation;
