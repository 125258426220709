import { useEffect, useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import Badge from 'components/badge';
import { Grid } from 'components/grid';
import Title from 'components/project/common/title';
import useDebounce from 'components/hook/useDebounce';
import DialogImport from './components/DialogImport';
import DialogUpdate from './components/DialogUpdate';
import DiagnosisCodesFilter from './components/Filter';
import DiagnosisControlPanel from './components/ControlPanel';

import {
  storeDiagnosisCode,
  FilterModel,
} from 'stores/_mobx/systemSetup/codeSet/diagnosisCodes';
import AccessUtils from 'utils/AccessUtils';
import { CODES_SET_DIAGNOSIS as PAGE_ID } from 'constant/pagesId/systemSetup';
import { URL_DIAGNOSIS_CODES } from 'constant/path/systemSetup';

const DiagnosisCodesListPage = () => {
  const gridRef = useRef(null);

  const {
    fetching,
    uploadingInProgress,
    codesList,
    codesTotal,
    page: { pagination, setPagination, setPaginationToStart },
    filter,
  } = storeDiagnosisCode;

  const [selected, setSelected] = useState<string[]>([]);

  const [isImportActive, setImportStatus] = useState<boolean>(false);
  const [isUpdateActive, setUpdateStatus] = useState<boolean>(false);

  const debouncedMethod = useDebounce(setPaginationToStart);

  const renderCell = (icdCode: string) =>
    filter.billable === '1' ? (
      icdCode
    ) : (
      <span className="text-danger">{icdCode}</span>
    );

  const columns = useMemo(
    () => [
      {
        name: 'icdCode',
        title: 'ICD Code',
        render: renderCell,
      },
      {
        name: 'shortDescription',
        title: 'ICD Description',
        render: renderCell,
      },
      {
        name: 'flag',
        title: 'Status',
        maxWidth: 80,
        render: (value: string) => (
          <Badge variant={value === 'A' ? 'success' : 'danger'}>
            {value === 'A' ? 'Active' : 'Inactive'}
          </Badge>
        ),
      },
      AccessUtils.checkAccessAny(PAGE_ID.EDIT, PAGE_ID.DELETE)
        ? {
            name: 'id',
            title: 'Control',
            maxWidth: 80,
            render: (id: number) => (
              <div className="control">
                {filter.status === 'A' &&
                  AccessUtils.get(
                    PAGE_ID.EDIT,
                    <Link
                      className="bi bi-pencil"
                      to={`${URL_DIAGNOSIS_CODES}/edit/${id}`}
                    />
                  )
                }
              </div>
            ),
          }
        : null,
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filter.billable, filter.status]
  );

  const handleChangeFilter = (filter: FilterModel) => {
    storeDiagnosisCode.setFilter(filter);

    debouncedMethod();
  };

  const handleChangeStatus = () => {
    storeDiagnosisCode
      .changeCodeStatus(selected, filter.status)
      .then((result) => {
        if (result) storeDiagnosisCode.getCodesList();
        setSelected([]);
      });
  };

  const handleClickExport = () => {
    storeDiagnosisCode.exportCodes(selected).then((result) => {
      if (result) gridRef.current?.clearSelection();
    });
  };

  const handleToggleImport = () => {
    setImportStatus((state) => !state);
  };

  const handleToggleUpdate = () => {
    setUpdateStatus((state) => !state);
  };

  const handleImport = (file: File) => {
    storeDiagnosisCode.importCodes(file).then((response) => {
      if (response) {
        setImportStatus(false);
        storeDiagnosisCode.getCodesList();
      }
    });
  };

  const handleUpdate = (file: File) => {
    storeDiagnosisCode.updateCodes(file).then((isSucceed) => {
      if (isSucceed) {
        setUpdateStatus(false);
        storeDiagnosisCode.getCodesList();
      }
    });
  };

  useEffect(() => {
    storeDiagnosisCode.getCodesList();
  }, [pagination]);

  return (
    <>
      <Title appId={PAGE_ID.PAGE} title="Diagnosis Codes" />

      <DiagnosisCodesFilter onChange={handleChangeFilter} />

      <Grid
        id="diagnosis_codes_grid"
        selectId="id"
        columns={columns}
        onAjax={fetching}
        dataSource={codesList}
        dataSourceCount={codesTotal}
        ref={gridRef}
        pagination={pagination}
        onSelectChange={setSelected}
        selectedIds={selected}
        onPaginationChange={setPagination}
        gridControlPanel={
          <DiagnosisControlPanel
            isActive={filter.status === 'A'}
            isButtonDisabled={!selected.length}
            basicUrl={URL_DIAGNOSIS_CODES}
            onChangeStatus={handleChangeStatus}
            onClickImport={handleToggleImport}
            onClickUpdate={handleToggleUpdate}
            onClickExport={handleClickExport}
          />
        }
      />

      {isImportActive && (
        <DialogImport
          fetching={uploadingInProgress}
          onSubmit={handleImport}
          onClose={handleToggleImport}
        />
      )}

      {isUpdateActive && (
        <DialogUpdate
          fetching={uploadingInProgress}
          onSubmit={handleUpdate}
          onClose={handleToggleUpdate}
        />
      )}
    </>
  );
};

export default observer(DiagnosisCodesListPage);
