import { useEffect, useMemo } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { LayoutSideTitle } from 'components/layout';
import { URL_JURISDICTION } from 'constant/path/systemSetup';
import JurisdictionDetailsForm from './form/JurisdictionDetailsForm';

import {
  JurisdictionFormType,
  storeJurisdiction
} from 'stores/_mobx/systemSetup/masterSetting/jurisdiction';

interface MatchParams {
  id: string;
}

interface PropsType extends RouteComponentProps<MatchParams> {}

const JurisdictionDetailsPage = ({
  match: { params },
  history,
}: PropsType) => {
  const isNew = params.id === 'new';
  const jurisdictionId = Number(params.id);

  const {
    fetching,
    jurisdictionsDetails,
    jurisdictionModalityList,
    jurisdictionZipCodeList,
    jurisdictionZipCodeRangesList,
  } = storeJurisdiction;

  const defaultValues = useMemo<JurisdictionFormType>(() => {
    return {
      refid: jurisdictionsDetails?.refid || 0,
      area: jurisdictionsDetails?.area || '',
      stateId: jurisdictionsDetails?.stateId || 0,
      modalityList: jurisdictionModalityList || [],
      zipCodes: jurisdictionZipCodeList || [],
      zipCodeRanges: jurisdictionZipCodeRangesList || []
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jurisdictionsDetails, jurisdictionModalityList]);

  const handleSubmit = (payload: JurisdictionFormType) => {
    const action = isNew
      ? storeJurisdiction.addJurisdiction
      : storeJurisdiction.updateJurisdiction;

    return action(payload).then((response: boolean) => {
      if (!response) return response;

      history.push(URL_JURISDICTION);
    });
  };

  useEffect(() => {
    storeJurisdiction.getJurisdictionData(jurisdictionId);

    return storeJurisdiction.clearJurisdictionData;
  }, [params.id, isNew, jurisdictionId]);

  return (
    <>
      <LayoutSideTitle title={`${isNew ? 'Add' : 'Edit'} Jurisdiction`}>
        <Link to={URL_JURISDICTION} className="btn btn-danger">
          Back
        </Link>
      </LayoutSideTitle>

      <JurisdictionDetailsForm
        fetching={fetching}
        isNew={isNew}
        id={jurisdictionId}
        defaultValues={defaultValues}
        onSubmit={handleSubmit}
      />
    </>
  );
};

export default observer(JurisdictionDetailsPage);
