import { RouteComponentProps } from 'react-router-dom';

import Title from 'components/project/common/title';
import Form from './components/Form';

import {
  storeZipCode,
  ZipCodeEditType,
} from 'stores/_mobx/systemSetup/masterSetting/zipCode';
import { URL_ZIP_CODES } from 'constant/path/systemSetup';

interface MatchParams {
  id: string;
}

interface PropsType extends RouteComponentProps<MatchParams> {}

const ZipCodeAddPage = ({ history }: PropsType) => {
  const handleSubmit = (payload: ZipCodeEditType) =>
    storeZipCode.addZipCode(payload).then((isSucceed) => {
      if (isSucceed) history.push(URL_ZIP_CODES);
    });

  return (
    <>
      <Title title="Add Zip code" />

      <Form onSubmit={handleSubmit} />
    </>
  );
};

export default ZipCodeAddPage;
