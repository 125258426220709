import { makeObservable, observable, action, runInAction } from 'mobx';

import Notification from 'components/modal/Notification';

import { apiRequest } from 'services/RequestService';
import Pagination from 'stores/_mobx/options/pagination';

const filterDefaultValues: FilterType = {
  stateId: 0,
  area: '',
};

export const defaultJurisdiction: JurisdictionDetailsType = {
  area: null,
  refid: 0,
  stateId: null,
};

export interface FilterType {
  stateId: number;
  area: string;
}

interface PaginationType {
  skip: number;
  limit: number;
}

export interface JurisdictionFormType {
  area: string;
  refid: number;
  stateId: number;
  modalityList: JurisdictionModalityType[],
  zipCodes: JurisdictionZipCodesType[];
  zipCodeRanges: JurisdictionZipCodeRangesType[];
}

export interface JurisdictionDetailsType {
  area: string;
  refid: number;
  stateId: number;
}

export interface JurisdictionZipCodesType {
  refid: string;
  jurisdiction: string;
  zip: string;
}

interface JurisdictionZipCodesListResponseType {
  result: JurisdictionZipCodesType[];
  totalCount: number;
}

export interface JurisdictionZipCodeRangesType {
  refid: string;
  jurisdiction: string;
  zipStart: string;
  zipEnd: string;
}

export interface ZipCodeRangesType
  extends Omit<JurisdictionZipCodeRangesType, 'refid' | 'jurisdiction'> {}

interface JurisdictionZipCodeRangesResponseType {
  result: JurisdictionZipCodeRangesType[];
  totalCount: number;
}

export interface JurisdictionType {
  area: string;
  refid: number;
  state: string;
  zipcode: string;
  zipcoderange: string;
}

interface JurisdictionAddType extends Omit<JurisdictionFormType, 'refid'> {}

interface JurisdictionListResponseType {
  result: JurisdictionType[];
  totalCount: string;
}

export interface JurisdictionModalityType {
  [key: string]: number | string;
  refid: number;
  exam_type_nm: string;
  modality: string;
  modality_id: number;
  npiname: string;
  npinumber: string;
}

interface OptionResponseType {
  data: string;
  label: string;
}

export interface OptionType {
  value: number;
  label: string;
}

class Jurisdiction {
  fetching: boolean = false;
  fetchingOptions: boolean = false;
  fetchingZipCodes: boolean = false;
  fetchingZipCodeRanges: boolean = false;
  jurisdictionsList: JurisdictionType[] = [];
  jurisdictionsTotal: number = 0;
  jurisdictionsDetails?: JurisdictionDetailsType;
  jurisdictionModalityList?: JurisdictionModalityType[] = [];
  jurisdictionZipCodeList?: JurisdictionZipCodesType[] = [];
  jurisdictionZipCodesTotal?: number = 0;
  jurisdictionZipCodeExists?: boolean = false;
  jurisdictionZipCodeRangesList?: JurisdictionZipCodeRangesType[] = [];
  jurisdictionZipCodeRangesTotal?: number = 0;
  jurisdictionZipCodeRangesExists?: boolean = false;
  idForDelete: number = 0;
  options: OptionType[] = [];
  filter: FilterType = filterDefaultValues;

  page: Pagination = new Pagination({ id: 'jurisdictionGrid' });

  constructor() {
    makeObservable(this, {
      fetching: observable,
      fetchingOptions: observable,
      fetchingZipCodes: observable,
      fetchingZipCodeRanges: observable,
      jurisdictionsList: observable,
      jurisdictionsTotal: observable,
      jurisdictionsDetails: observable,
      jurisdictionModalityList: observable,
      jurisdictionZipCodeList: observable,
      jurisdictionZipCodesTotal: observable,
      jurisdictionZipCodeRangesList: observable,
      jurisdictionZipCodeRangesTotal: observable,
      jurisdictionZipCodeExists: observable,
      jurisdictionZipCodeRangesExists: observable,
      idForDelete: observable,
      options: observable,
      filter: observable,

      setFetching: action,
      setFetchingOptions: action,
      setFetchingZipCodes: action,
      setFetchingZipCodeRanges: action,
      setJurisdictions: action,
      setJurisdictionsDetails: action,
      setJurisdictionModalityList: action,
      setJurisdictionZipCodes: action,
      setJurisdictionZipCodeExists: action,
      setJurisdictionZipCodeRanges: action,
      setJurisdictionZipCodeRangesExists: action,
      setIdForDelete: action.bound,
      setFilter: action.bound,
      clearIdForDelete: action.bound,
      clearJurisdictionsDetails: action.bound,
      clearJurisdictionsModalityList: action.bound,
      clearJurisdictionData: action.bound,
      clearJurisdictionsZipCodes: action.bound,
      clearJurisdictionsZipCodeRanges: action.bound,
    });
  }

  setFetching(fetching: boolean) {
    this.fetching = fetching;
  }

  setFetchingOptions(fetching: boolean) {
    this.fetchingOptions = fetching;
  }

  setFetchingZipCodes(fetchingZipCodes: boolean) {
    this.fetchingZipCodes = fetchingZipCodes;
  }

  setFetchingZipCodeRanges(fetchingZipCodes: boolean) {
    this.fetchingZipCodeRanges = fetchingZipCodes;
  }

  setJurisdictions(list: JurisdictionType[], total: number) {
    this.jurisdictionsList = list;
    this.jurisdictionsTotal = total;
  }

  setJurisdictionsDetails(details: JurisdictionDetailsType) {
    this.jurisdictionsDetails = details;
  }

  setJurisdictionModalityList(modalityList: JurisdictionModalityType[]) {
    this.jurisdictionModalityList = modalityList;
  }

  setJurisdictionZipCodes({
    zipCodes,
    totalCount
  }: {
    zipCodes: JurisdictionZipCodesType[],
    totalCount: number
  }) {
    this.jurisdictionZipCodeList = zipCodes;
    this.jurisdictionZipCodesTotal = totalCount;
  }

  setJurisdictionZipCodeRanges({
    zipCodes,
    totalCount
  }: {
    zipCodes: JurisdictionZipCodeRangesType[],
    totalCount: number
  }) {
    this.jurisdictionZipCodeRangesList = zipCodes;
    this.jurisdictionZipCodeRangesTotal = totalCount;
  }

  setJurisdictionZipCodeExists(isZipCodeExist: boolean) {
    this.jurisdictionZipCodeExists = isZipCodeExist;
  }

  setJurisdictionZipCodeRangesExists(isZipCodeRangesExist: boolean) {
    this.jurisdictionZipCodeRangesExists = isZipCodeRangesExist;
  }

  setIdForDelete(id: number) {
    this.idForDelete = id;
  }

  setFilter(filter: FilterType) {
    this.filter = filter;
  }

  clearIdForDelete() {
    this.idForDelete = 0;
  }

  clearJurisdictionsDetails() {
    this.jurisdictionsDetails = null;
  }

  clearJurisdictionsModalityList() {
    this.jurisdictionModalityList = [];
  }

  clearJurisdictionData() {
    this.clearJurisdictionsDetails();
    this.clearJurisdictionsModalityList();
  }

  clearJurisdictionsZipCodes() {
    this.jurisdictionZipCodeList = [];
    this.jurisdictionZipCodesTotal = 0
  }

  clearJurisdictionsZipCodeRanges() {
    this.jurisdictionZipCodeRangesList = [];
    this.jurisdictionZipCodeRangesTotal = 0;
  }

  async getJurisdictionList() {
    const {
      filter,
      page: { pagination },
    } = this;

    this.setFetching(true);
    try {
      const { totalCount, result } =
        await apiRequest<JurisdictionListResponseType>({
          url: 'generalmaster.jurisdiction.GetJurisdictions',
          data: [
            pagination.skip,
            pagination.pageSize,
            filter.stateId,
            filter.area,
          ],
        });

      const list = result.map((el) => ({
        ...el,
        refid: Number(el.refid),
      }));

      this.setJurisdictions(list, Number(totalCount) || 0);
    } catch (e: any) {
      this.setJurisdictions([], 0);
    } finally {
      this.setFetching(false);
    }
  }

  async addJurisdiction(jurisdiction: JurisdictionAddType) {
    const data = [
      jurisdiction.stateId,
      jurisdiction.area,
      jurisdiction.zipCodes,
      jurisdiction.zipCodeRanges,
      jurisdiction.modalityList,
    ];

    try {
      const response = await apiRequest<'SE' | 'S' | 'E'>({
        url: 'generalmaster.jurisdiction.AddJurisdiction',
        data,
      });

      if (response === 'S') {
        Notification.success('Jurisdiction added successfully!');
        return true;
      } else if (response === 'E') {
        Notification.danger('Same jurisdiction area already exists!');
        return false
      }

      throw Error('');
    } catch (e: any) {
      Notification.danger('An error occurred!');
      return false;
    }
  }

  async updateJurisdiction(jurisdiction: JurisdictionFormType) {
    const data = [
      jurisdiction.refid,
      jurisdiction.stateId,
      jurisdiction.area,
      jurisdiction.zipCodes,
      jurisdiction.zipCodeRanges,
      jurisdiction.modalityList,
    ];

    try {
      const response = await apiRequest<'SE' | 'S' | 'E'>({
        url: 'generalmaster.jurisdiction.EditJuridiction',
        data,
      });

      if (response === 'S') {
        Notification.success('Jurisdiction updated successfully!');
        return true;
      } else if (response === 'E') {
        Notification.danger('Same jurisdiction area already exists!');
        return false;
      }

      throw Error('');
    } catch (e: any) {
      Notification.danger('An error occurred!');
      return false;
    }
  }

  async deleteJurisdiction() {
    this.setFetching(true);
    try {
      const response = await apiRequest<'SE' | 'S'>({
        url: 'generalmaster.jurisdiction.DeleteJurisdiction',
        data: [this.idForDelete],
      });
      if (response === 'S') {
        this.clearIdForDelete();
        Notification.success('Jurisdiction deleted successfully!');
        return true;
      }
      throw Error('');
    } catch (e: any) {
      Notification.danger('An error occurred!');
      this.setFetching(false);
      return false;
    }
  }

  async getJurisdictionDetails(id: number) {
    this.setFetching(true);
    try {
      const response = await apiRequest<JurisdictionDetailsType>({
        url: 'generalmaster.jurisdiction.GetJurisdiction',
        data: [id],
      });

      const details = {
        ...response,
        stateId: Number(response.stateId)
      };

      this.setJurisdictionsDetails(details);
    } catch (e: any) {
      this.setJurisdictionsDetails(defaultJurisdiction);
      Notification.danger('An error occurred!');
    } finally {
      this.setFetching(false);
    }
  }

  async getJurisdictionModalityList(jurId: number) {
    this.setFetching(true);

    try {
      const { result } = await apiRequest<{ result: Array<JurisdictionModalityType> }>({
        url: 'generalmaster.jurisdiction.GetJurisdictionModalities',
        data: [jurId],
      });

      this.setJurisdictionModalityList(result);
    } catch (e: any) {
      Notification.danger('An error occurred!');
      this.setJurisdictionModalityList([]);
    } finally {
      this.setFetching(false);
    }
  }

  async getJurisdictionData(id: number) {
    await this.getJurisdictionDetails(id);
    await this.getJurisdictionModalityList(id);
  }

  async getJurisdictionZipCodes(
    jurId: number,
    filter: { zip: string },
    pagination: PaginationType
  ) {
    this.setFetchingZipCodes(true);
    try {
      const { result, totalCount } = await apiRequest<JurisdictionZipCodesListResponseType>({
        url: 'generalmaster.jurisdiction.GetJurisdictionZipCodes',
        data: [jurId, filter, pagination],
      });

      this.setJurisdictionZipCodes({ zipCodes: result, totalCount});
    } catch (e: any) {
      Notification.danger('An error occurred!');
      this.setJurisdictionZipCodes({ zipCodes: [], totalCount: 0 });
    } finally {
      this.setFetchingZipCodes(false);
    }
  }

  async getJurisdictionZipCodeExists(jurId: number, zip: string): Promise<boolean> {
    this.setFetching(true);
    try {
      const response = await apiRequest<boolean>({
        url: 'generalmaster.jurisdiction.JurisdictionZipCodeExists',
        data: [jurId, zip],
      });

      this.setJurisdictionZipCodeExists(response);
      this.setFetching(false);

      return response;
    } catch (e: any) {
      Notification.danger('An error occurred!');
      this.setJurisdictionZipCodeExists(false);
      this.setFetching(false);

      return false;
    }
  }

  async addJurisdictionZipCode(jurId: number, zip: string) {
    this.setFetching(true);

    try {
      const response = await apiRequest<'SE' | 'S'>({
        url: 'generalmaster.jurisdiction.AddJurisdictionZipCode',
        data: [jurId, zip],
      });

      if (response === 'S') {
        Notification.success('Successfully added!');
        this.setFetching(false);
        return true;
      }

      this.setFetching(false);
      throw Error('');
    } catch (e: any) {
      Notification.danger('An error occurred!');
      this.setFetching(false);
      return false;
    }
  }

  async deleteJurisdictionZipCode(jurId: number, zip: string) {
    this.setFetching(true);

    try {
      const response = await apiRequest<'SE' | 'S'>({
        url: 'generalmaster.jurisdiction.DeleteJurisdictionZipCode',
        data: [jurId, zip],
      });

      if (response === 'S') {
        Notification.success('Successfully deleted!');
        this.setFetching(false);
        return true;
      }

      this.setFetching(false);
      throw Error('');
    } catch (e: any) {
      Notification.danger('An error occurred!');
      this.setFetching(false);
      return false;
    }
  }

  async getJurisdictionZipCodeRanges(
    jurId: number,
    filter: ZipCodeRangesType,
    pagination: PaginationType
  ) {
    if ((jurId + '').toLowerCase() === 'new') {
      return;
    }

    this.setFetchingZipCodeRanges(true);

    try {
      const {
        result,
        totalCount
      } = await apiRequest<JurisdictionZipCodeRangesResponseType>({
        url: 'generalmaster.jurisdiction.GetJurisdictionZipCodeRanges',
        data: [jurId, filter, pagination],
      });

      this.setJurisdictionZipCodeRanges({ zipCodes: result, totalCount });
    } catch (e: any) {
      Notification.danger('An error occurred!');
      this.setJurisdictionZipCodeRanges({ zipCodes: [], totalCount: 0 });
    } finally {
      this.setFetchingZipCodeRanges(false);
    }
  }

  async getJurisdictionZipCodeRangesExists(
    jurId: number,
    zipStart: string,
    zipEnd: string
  ): Promise<boolean> {
    this.setFetching(true);
    try {
      const response = await apiRequest<boolean>({
        url: 'generalmaster.jurisdiction.JurisdictionZipCodeRangeExists',
        data: [jurId, zipStart, zipEnd],
      });

      this.setJurisdictionZipCodeRangesExists(response);
      this.setFetching(false);

      return response;
    } catch (e: any) {
      Notification.danger('An error occurred!');
      this.setJurisdictionZipCodeRangesExists(false);
      this.setFetching(false);

      return false;
    }
  }

  async addJurisdictionZipCodeRange(
    jurId: number,
    zipStart: string,
    zipEnd: string
  ) {
    this.setFetching(true);

    try {
      const response = await apiRequest<'SE' | 'S'>({
        url: 'generalmaster.jurisdiction.AddJurisdictionZipCodeRange',
        data: [jurId, zipStart, zipEnd],
      });

      if (response === 'S') {
        Notification.success('Successfully added!');
        this.setFetching(false);
        return true;
      }

      this.setFetching(false);
      throw Error('');
    } catch (e: any) {
      Notification.danger('An error occurred!');
      this.setFetching(false);
      return false;
    }
  }

  async deleteJurisdictionZipCodeRange(
    jurId: number,
    zipStart: string,
    zipEnd: string
  ) {
    this.setFetching(true);

    try {
      const response = await apiRequest<'SE' | 'S'>({
        url: 'generalmaster.jurisdiction.DeleteJurisdictionZipCodeRange',
        data: [jurId, zipStart, zipEnd],
      });

      if (response === 'S') {
        Notification.success('Successfully deleted!');
        this.setFetching(false);
        return true;
      }

      this.setFetching(false);
      throw Error('');
    } catch(e: any) {
      Notification.danger('An error occurred!');
      this.setFetching(false);
      return false;
    }
  }

  getOptions = async () => {
    if (this.fetchingOptions) return Promise.resolve([]);

    this.setFetchingOptions(true);

    try {
      const response = await apiRequest<OptionResponseType[]>({
        url: 'generalmaster.jurisdiction.GetjurisdictionDropdown',
      });

      const options = response
        .map(({ label, data }) => ({
          label,
          value: Number(data),
        }))
        .sort((a, b) => a.label.localeCompare(b.label));

      runInAction(() => {
        this.fetchingOptions = false;
        this.options = options;
      });
    } catch (error) {
      runInAction(() => {
        this.fetchingOptions = false;
        this.options = [];
      });
    }
  };
}

export const storeJurisdiction = new Jurisdiction();
